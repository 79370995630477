.content-portal{
    overflow: hidden;
}


input::-ms-clear {
    display: none;
}
.info-lines{
	position: relative;
	border-bottom: 2px solid #D9D9D9;
}
.m-search-input{
	width: 100%;
	margin-right: 40px;
	height: 50px;
	padding: 6px 12px;
	font-size: 16px;
	line-height: 1.42857143;
	color: #555;
	background-color: #F0F3F5;
	background-image: none;
	border: none;
	box-shadow: inset 0 3px 5px -1px #8F9495;
	font-family: RobotoCondensedLight,sans-serif;
	outline: none;
}
.m-search-submit{
	position: absolute;
	display: inline-block;
	height: 50px;
	width: 50px;
	background-color: #0064aa;
	border: none;
	border-radius: 0;
	color: #fff;
	right: 0;
	top: 0;
}
.m-search-submit i{
	font-size: 20px;
}

.shop-link{
	background: #0064aa;
	color: #fff;
	text-align: center;
}

.js-mobile-nav-placeholder .shop-link {
    min-height: 50px;
}

.shop-link a{
	color: #fff;
	height: 50px;
	font-size: 20px;
	padding: 13px 0 10px;
	display: block;
}
.shop-link i{
	margin-right: 5px;
	margin-bottom: -3px;
}


.max-height-100p,
.portal-live-info .container-fluid,
.portal-tipps .container-fluid,
.portal-bergerlebnisse .container-fluid,
.portal-anreise .container-fluid,
.portal-ecommerce-teaser-xs .container-fluid{
    height: 100%;
}

.portal-slider,
.portal-slider .image-holder{
    height: 850px;
}

.portal-slider .image-holder__img {
    transform: translateX(0);
}

.portal-slider .image-holder,
.portal-live-info .image-holder,
.portal-bergerlebnisse .image-holder{
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    background-size: cover;
    background-position: top;
}
.portal-bergerlebnisse .image-holder {
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-color: #a8000f;
}

.portal-slider .image-holder{
    background-position: center;
}

.portal-tipps .image-holder{
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    /*background-size: cover;*/
    background-size: 100% auto;
    background-position: top;
    background-repeat: no-repeat;
    background-color: #004679;
}

.portal-tipps,
.portal-tipps .image-holder,
.portal-bergerlebnisse,
.portal-bergerlebnisse .image-holder,
.portal-anreise,
.portal-anreise .image-holder{
    height: auto;
    position: relative;
}

.portal-tipps .container,
.portal-bergerlebnisse .container,
.portal-live-info .container,
.portal-live-info .image-holder{
    position: relative;
}




/* ---------------------------------------  E-Commerce Teaser ---------------------------------------------*/

.portal-ecommerce-teaser{
    height: 100%;
    width: 100%;
    z-index: 10;
	position: relative;
    background-color: transparent;
	margin-top: -25.6%;
}

.portal-ecommerce-teaser-holder {
    opacity: 1;
    transform: none;
}

.portal-slider .container-fluid{
    position: relative;
}

.portal-tipps{
    z-index: 4;
}

.ecommerce-content{
    padding: 0;
    cursor: pointer;
    text-align: center;
}

.ecommerce-category{
	-webkit-backface-visibility: hidden;
    position: absolute;
    bottom: 47px;
    width: 100%;
    left: 0;
    z-index: 2;
    font-family: RobotoCondensedBold, sans-serif;
    font-size: 20px;
    color: #0064aa;
}

.ecommerce-headline{
	-webkit-backface-visibility: hidden;
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 36px;
    color: #004678;
    position: absolute;
    bottom: 0;
    z-index: 2;
    width: 100%;
    left: 0;
}

.ecommerce-link{

    font-family: RobotoCondensedBold, sans-serif;
    font-size: 16px;
    color: #0064aa;
    text-transform: uppercase;
    position: absolute;
    bottom: -40px;
    z-index: 2;
    width: 100%;
    left: 0;
}

.ecommerce-link a,
.tipps-teaser-link a,
.tipps-read-more a{
    text-decoration: none;
}

.ecommerce-link:before,
.tipps-teaser-link:before,
.tipps-read-more:before{
    position: relative;
    content: "\E002";
    font-family: iconfont;
    font-size: 10px;
    left: -3px;
}


.ecommerce-content:after{
    content: '';
    position: absolute;
    z-index: 0;
    opacity: 1;
    left: 0;
    right: 0;
    bottom: 0;
}

.ecommerce-content.verlauf-black:after,
.tipps-teaser-tipp.verlauf-black:after{
    position: absolute;
    bottom: 0;
    top: 0;
    box-shadow: inset 0 -195px 50px -30px rgba(0,0,0,.6);
    transform: translateY(28%);
    transition: transform 170ms ease-out;
}

.ecommerce-content.verlauf-white:after,
.tipps-teaser-tipp.verlauf-white:after{
    position: absolute;
    bottom: 0;
    top: 0;
    box-shadow: inset 0 -195px 50px -30px rgba(255,255,255,.9);
    transform: translateY(28%);
    transition: transform 170ms ease-out;
}
.tipps-teaser-tipp.verlauf-white:hover:after,
.tipps-teaser-tipp.verlauf-black:hover:after,
.portal-ecommerce-teaser-holder:hover .verlauf-white:after,
.portal-ecommerce-teaser-holder:hover .verlauf-black:after {
    transform: translateY(15%);
}


.portal-ecommerce-teaser-holder:after {
    content: '';
    box-shadow: inset -9px 0 29px -15px #999;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    width: 16px;
}

/*hover overlay*/
.portal-ecommerce-teaser-holder > .embed-responsive:before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    opacity: 0;
    transition: opacity 1000ms ease-out;
    z-index: 1;
}
.portal-ecommerce-teaser-holder:hover > .embed-responsive:before {
    opacity: .25;
}

.portal-ecommerce-teaser-holder .ecommerce-content {
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    pointer-events: none;
}
@media screen and (min-width: 768px) {
    .portal-ecommerce-teaser-holder .ecommerce-content {
        z-index: 2;
    }
}
.tipps-teaser-category,
.ecommerce-category {
    transition: transform 140ms 20ms ease;
    pointer-events: auto;
}
.tipps-teaser-headline,
.ecommerce-headline {
    transition: transform 140ms 20ms ease;
    pointer-events: auto;
}
.tipps-teaser-link,
.ecommerce-link {
    transition: transform 200ms 20ms ease-out;
    pointer-events: auto;
}
.portal-ecommerce-teaser-holder:hover .ecommerce-category {
    transform: translateY(-30px);
}
.portal-ecommerce-teaser-holder:hover .ecommerce-headline {
    transform: translateY(-30px);
}
.portal-ecommerce-teaser-holder:hover .ecommerce-link {
    transform: translateY(-45px);
}
.tipps-teaser-tipp,
.portal-ecommerce-teaser-holder{
	padding: 0;
	cursor: pointer;
}
.tipps-teaser-content {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    transform: translateY(18px);
    transition: transform 140ms 20ms ease-out;
}
.tipps-teaser-tipp:hover .tipps-teaser-content {
    transform: translateY(-10px);
}

/* -------------------------------------------------  Section Portal Tipps --------------------------------------------*/

.portal-headline-tipps{
    margin-top: 400px;
    text-align: center;
}
.portal-headline-tipps h1{
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 70px;
    color: #fff;
}
.tipps-box{
    margin-top: 40px;
    display: inline-block;
}

.portal-tipps .image-holder:before,
.portal-bergerlebnisse .image-holder:before,
.portal-live-info .image-holder:before{
    content: '';
    position: absolute;
    opacity: 1;
    bottom: 0;
    left: 0;
    right: 0;
    top: 0;

}

/*.portal-tipps .image-holder:before,*/
/*.portal-live-info .image-holder:before{*/
    /*background: rgba(0,70,120,.8);*/
/*}*/
.portal-bergerlebnisse.background-white-border-top .image-holder:before{
	background: #fff;
	border-top: 1px solid #efefef;
}

.tipps-teaser-tipp{
    overflow: hidden;
    position: relative;
    height: 290px;
    text-align: center;
}

.tipps-teaser-tipp:after {
    content: '';
    position: absolute;
    z-index: 0;
    opacity: 1;
    bottom: 0;
    left: 0;
    right: 0;
}

.tipps-teaser-category{
    font-family: RobotoCondensedBold, sans-serif;
    font-size: 24px;
    color: #0064aa;
    z-index: 1;
}

.tipps-teaser-headline{
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 32px;
    color: #004678;
    z-index: 1;
}
.verlauf-black .tipps-teaser-headline{
	color: #fff;
}

.tipps-teaser-link{
    font-family: RobotoCondensedBold, sans-serif;
    font-size: 16px;
    color: #0064aa;
    text-transform: uppercase;
    z-index: 1;
    transform: translateY(20px);
    transition: transform 110ms 80ms ease-out;
}
.tipps-teaser-tipp:hover .tipps-teaser-link {
    transform: translateY(0);
}

.tipps-teaser-event-small .tipps-teaser-headline{
    color: #fff;
}

.tipps-teaser-event-big {
    background-color: #f3f3f3;
    overflow: hidden;
}

.tipps-img__img{
    position: relative;
    overflow: hidden;
    margin: 35px;
    margin-bottom: 0;
}

/*hover overlay*/
.tipps-img__img:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    background: #fff;
    transition: opacity 1000ms ease-out;
    pointer-events: none;
}
.tipps-teaser-event-big:hover .tipps-img__img:after{
    opacity: .2;
}

.tipps-event-content{
    position: relative;
    background: inherit;
    padding-left: 35px;
    padding-right: 35px;
    padding-bottom: 20px;
    padding-top: 20px;
    transition: transform 130ms ease-out;
}
.tipps-teaser-event-big:hover .tipps-event-content {
    transform: translateY(-30px);
}

.tipps-content-headline{
    font-family: RobotoCondensedBold, sans-serif;
    font-size: 22px;
    color: #0064aa;
}

.tipps-content{
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 16px;
}

.tipps-read-more{
    font-family: RobotoCondensedBold, sans-serif;
    font-size: 16px;
    text-transform: uppercase;
    margin-top: 5px;
    color: #0064aa;
    position: absolute;
    top: 100%;
    transition: transform 130ms ease-out;
}
.tipps-teaser-event-big:hover .tipps-read-more {
    transform: translateY(-20px);
}


.angebot-banner{
    background-color: #2a8bd1;
}
.angebot-banner > a {
    text-decoration: none;
}

.banner-txt{
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 18px;
    color: #fff;
    margin-left: 30px;
    margin-right: 30px;
    padding-top: 10px;
    padding-bottom: 5px;
    text-decoration: none;
}

.angebot-img {
    position: relative;
}
.angebot-img:after {
    content: '';
    display: block;
    background: #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: all 1000ms ease-out;
    pointer-events: none;
}
.angebot-teaser:hover .angebot-img:after {
    opacity: .2;
}

.angebot-price{
    position: absolute;
    background-color: #2a8bd1;
    margin-top: -1px;
    z-index: 2;
}

.angebot-price-txt{
    text-transform: uppercase;
    color: #fff;
    font-family: RobotoCondensedBold, sans-serif;
    font-size: 28px;

    margin-left: 30px;
    margin-right: 30px;
    padding-top: 5px;
    padding-bottom: 5px;
}

.angebot-price-txt span{
    font-size: 20px;
}

.brighter-background{
    background-color: #fafafa;
}

.searching-box-top{
    background-color: #a8000e;
    text-align: center;
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 36px;
    color: #fff;
}


.searching-headline{
    padding-top: 20px;
    padding-bottom: 20px;
}

#tab-name{
    height: 388px;
}

.searching-tabs-txt{
    font-size: 20px;
    font-family: RobotoCondensedLight, sans-serif;
}

.tipps-searching-tabs .nav-tabs{
    border-color: transparent;
}

.tipps-searching-tabs .nav-tabs > li > a{
    border-color: transparent;
    color: #fff;
}
.tipps-searching-tabs .nav-tabs > li > a:hover{
    background-color: transparent;
    border-radius: 0;
    border-color: transparent;
}
.tipps-searching-tabs .nav-tabs > li {
    border-right: 1px solid #0064aa;
}
.tipps-searching-tabs .nav-tabs > li:last-child, .tipps-searching-tabs .nav-tabs > li.active {
    border-right: 0;
}
.tipps-searching-tabs .nav-tabs > li.active > a,
.tipps-searching-tabs .nav-tabs > li.active > a:hover{
    color: #fff;
    border-radius: 0;
    border-color: transparent;
    background-color: #0064aa;
}

.tipps-searching-content{
    background-color: #0064aa;
    /*padding-bottom: 30px;*/
    position: relative;
    /*height: 388px;*/
}

.searching-txt-area{
    text-align: left;
    font-size: 18px;
    padding-left: 40px;
    padding-right: 40px;
    padding-top: 28px;
    padding-bottom: 20px;
}



.portal-searching-form{
    /*margin-left: 40px;*/
    /*margin-right: 40px;*/
}

.portal-searching-form img{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
}

.form-portal-date{
    margin: 0 40px;
}

.txt-anreise,
.txt-abreise,
.txt-room-cat,
.txt-event-from,
.txt-event-to{
    text-align: left;
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 20px;
}

.tipps-searching-box .form-group{
    position: relative;
}

.tipps-searching-box .form-group.is-open:after{
    top: 100%;
    left: 50%;
    border: solid transparent;
    border-top-color: #fff;
    content: '';
    position: absolute;
    border-width: 7px;
    margin-left: -7px;
    z-index: 200;
}

.portal-search-date i{
    color: #ff7f7f;
    position: absolute;
    right: 11px;
    top: 17px;
    font-size: 16px;
}

.portal-search-date .is-open i{
    color: #ccc;
}

.portal-search-date .form-control,
#quickfinder select{
    border: none;
    border-radius: 0;

    height: 50px;
    cursor: pointer;
    color: #fff;
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 18px;

    box-shadow:inset 0 0 50px -6px #333333;

    background-color: #c20010;

}

.portal-search-date .is-open .form-control{
    background-color: #fff;
    box-shadow: none;
    color: #000;
}


#quickfinder select{
    float: left;
    width: 100%;
    outline: none;
    font-family: RobotoCondensedLight,sans-serif;
}

#quickfinder select option {
    background-color: #f5f5f5;
    color: #000;
}

a.quickfinder__detail-search{
    display: block;
    font-size: 16px;
    color: #ffb2b2;
    text-decoration: underline;
    margin-top: -12px;
    margin-bottom: 20px;
}

.tipps-social-box{
    height: 175px;

    text-align: center;

    background: rgb(169,3,41);
    background: -moz-linear-gradient(left,  rgba(169,3,41,1) 0%, rgba(241,241,241,1) 0%, rgba(217,217,217,1) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(169,3,41,1)), color-stop(0%,rgba(241,241,241,1)), color-stop(100%,rgba(217,217,217,1)));
    background: -webkit-linear-gradient(left,  rgba(169,3,41,1) 0%,rgba(241,241,241,1) 0%,rgba(217,217,217,1) 100%);
    background: -o-linear-gradient(left,  rgba(169,3,41,1) 0%,rgba(241,241,241,1) 0%,rgba(217,217,217,1) 100%);
    background: -ms-linear-gradient(left,  rgba(169,3,41,1) 0%,rgba(241,241,241,1) 0%,rgba(217,217,217,1) 100%);
    background: linear-gradient(to right,  rgba(169,3,41,1) 0%,rgba(241,241,241,1) 0%,rgba(217,217,217,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a90329', endColorstr='#d9d9d9',GradientType=1 );

}

.tipps-social-box h2{
    font-family: RobotoCondensedLight,sans-serif;
    font-size: 36px;
    color: #333;
    margin-top: 0;
    padding-top: 30px;
}

.tipps-slider .slick-dots{
    bottom: -70px;
}

.tipps-slider .slick-dots li button:before{
    color: #fff;
    opacity: 0.7;
}
.tipps-slider .slick-dots li.slick-active button:before{
    color: #fff;
    opacity: 1;
}

.more-articles{
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 24px;
    color: #fff;
    padding-bottom: 60px;
}

.tipps-slider{
    padding-bottom: 50px;
}

.placeholder{
    padding-bottom: 40px;
    color: transparent;
}

/* Grid Salvattore */

#grid[data-columns]::before {
    content: '3 .column.size-1of3';
}

.column { float: left; }
.size-1of1 { width: 75%; }
.size-1of2 { width: 50%; }
.size-1of3 { width: 33.333%; }


/* ---------------------------------------------- Section Portal BERGERLEBNISSE  ---------------------------------------------------- */

.portal-ecommerce-teaser .verlauf-white{
	padding: 0;
}

.portal-headline-bergerlebnisse{
    margin-top: 60px;
}

.portal-headline-bergerlebnisse h3{
    font-family: RobotoCondensedBold, sans-serif;
    color: #fff;
    font-size: 24px;
    text-transform: uppercase;
    text-align: center;
}

.portal-headline-bergerlebnisse h1{
    font-family: RobotoCondensedLight, sans-serif;
    color: #fff;
    font-size: 70px;
    text-transform: uppercase;
    text-align: center;
    margin-top: 10px;

}


.bergerlebnisse.angebot-teaser{
    margin-top: 40px;
}
.bergerlebnisse .angebot-banner,
.bergerlebnisse .angebot-price{
    background-color: #fff;
}

.bergerlebnisse .banner-txt{
    color: #000;
}

.bergerlebnisse .angebot-price-txt{
    color: #0064aa;
}

.banner-txt span{
    font-family: RobotoCondensedBold, sans-serif;
}


/* Slider Bergerlebnisse */

.portal-bergerlebnis-slider{
    padding-bottom: 50px;
}

.bergerlebnisse-slider.slick-initialized .slick-slide {
    display: block;
    padding-left: 15px;
    padding-right: 15px;
}

.bergerlebnisse-slider .slick-next:before{
    content: "\E002";
    font-family: 'iconfont';
    color: #fff;
}

.bergerlebnisse-slider .slick-prev:before{
    content: "\E002";
    font-family: 'iconfont';

    color: #fff;
}

.bergerlebnisse-slider .slick-prev{
    transform: rotate(180deg);
    left: -30px;
}

.bergerlebnisse-slider .slick-next{
    right: -30px;
}

.bergerlebnisse-slider .slick-prev,
.bergerlebnisse-slider .slick-next{
    background-color: #0064aa;
    width: 30px;
    height: auto;
    position: absolute;
    bottom: 125px;
    top: 166px;
}

/* ---------------------------------------------- Section Portal Live Info ----------------------------------------------------*/

.portal-live-info .info-nav-box{
    z-index: 1;
}

.nav-live-info-mobile{
    text-align: center;
    display: inline-block;
}

.nav-live-info-mobile .info-text {
    display: block;
}

.nav-live-info-mobile .col-xs-6{
    z-index: 1;
}

.nav-live-info-mobile .col-xs-6.webcams-mobile .info-text{
    top: 13px;
}

.nav-live-info-mobile .col-xs-6 {

    border-radius: 5px;

    background-color: #003e6b;
    height: 60px;
    width: 140px;
    /*padding-top: 10px;
    padding-bottom: 10px;*/
    padding-left: 20px;
    padding-right: 20px;

    margin-top: 10px;

    box-shadow:inset -5px 5px 15px 2px #003861;
}

.nav-live-info-mobile .col-xs-6:hover {
    background-color: #004374;
    box-shadow: inset -5px 5px 15px 2px #013c67;
}

.nav-live-info-mobile .col-xs-6 a {
    display: block;
    color: #fff;
    font-family: RobotoLight;
    font-size: 12px;
    text-decoration: none;

}

.nav-live-info-mobile .col-xs-6 a:hover {
    color: #d7d7d7;
}

.nav-live-info-mobile .number{
    top: -1px;
    left: 0px;
}

.nav-live-info-mobile .avalanche-square{
    top: 13px;
    left: 0;
}

.nav-live-info-mobile .col-xs-6 i {
    position: relative;
    font-size: 22px;
    top: 10px;
}

.nav-live-info-mobile .col-xs-6 span {
    position: relative;
    top: 8px;
}


.nav-live-info-mobile .live-info-temp img{
    position: relative;
    height: 30px;
    top: 4px;
}

.nav-live-info-mobile .weather-info {
    font-family: RobotoBold, sans-serif;
    left: 8px;
    font-size: 16px;
}

.nav-live-info-mobile .avalanche-mobile span{
    top: 17px;
}

/* ---------------------------------------------- Section Portal ANREISE  ----------------------------------------------------*/
.map-holder-data,
.map-holder{
	background-repeat: no-repeat;
	background-position: 220px -9px;
	min-height: 490px;
}
.show-map-holder-data{
	background-repeat: no-repeat;
	background-position: 220px -550px;
	min-height: 490px;
}
.show-map-holder-data.show-car{
	background-repeat: no-repeat;
	background-position: 220px -1090px;
	min-height: 490px;
}
.show-map-holder-data.show-airplane{
	background-repeat: no-repeat;
	background-position: 220px -1630px;
	min-height: 490px;
}

.anreise-controls{
	position: absolute;
	right: 15px;
	top: 20px;
}
.anreise-controls .control{
	display: inline-block;
	width: 60px;
	height: 40px;
	position: relative;
	font-size: 20px;
	/*border-top: 2px solid transparent;*/

    background: rgb(255,255,255);
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(243,243,243,1) 100%);
    overflow: hidden;
    cursor: pointer;
}

.anreise-controls .control.active,
.anreise-controls .control:hover,
.anreise-controls .control.focus{
	/*border-top: 3px solid #0064aa;*/
	color: #0064aa;
}
.anreise-controls .control:before {
    content: '';
    display: block;
    height: 3px;
    position: absolute;
    top: 0;
    left: -3px;
    right: -3px;
    background: #0064aa;
    transform: scaleX(0) skewX(-45deg);
    transition: transform 110ms ease-out;
    /*transform-origin: left center;*/
}
.anreise-controls .control.active:before {
    transform: scaleX(1) skewX(-45deg);
}
.anreise-controls .control i{
	position: absolute;
	left: 50%;
	top: 50%;
	margin-left: -10px;
	margin-top: -10px;
}

.control-car{
    left: -4px;
}

.control-airplane{
    left: -8px;
}

.portal-anreise-content{
    margin-top: 50px;
    color: #0065aa;
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 36px;
	margin-bottom: 50px;
}
.portal-anreise-map{
	min-height: 300px;
}

.txt-bold{
    font-family: RobotoCondensedBold, sans-serif;
}

.anreise-txt-area{
    font-family: RobotoLight, sans-serif;
    font-size: 16px;
    color: #333333;
    width: 300px;
    margin-top: 20px;
}

/* ----------------------------------------- MEDIA  QUERIES ----------------------------------------- */

@media (min-width: 768px) and (max-width: 990px) {

    .show-map-holder-data.show-car{
        background-position: -150px -1090px;
    }
    .show-map-holder-data.show-airplane{
        background-position: -150px -1630px;
    }

    .show-map-holder-data {
        background-position: -150px -550px;
    }

    .map-holder-data {
        background-position: -149px -9px;
    }

    .portal-anreise-content{
        margin-top: 0;
    }

    .bergerlebnisse-slider .slick-next {
        right: -17px;
    }

    .bergerlebnisse-slider .slick-prev {
        left: -15px;
    }
}


@media (min-width: 990px) and (max-width: 1200px) {

    .map-holder-data{
        background-position: 50px -9px;
    }

    .show-map-holder-data.show-car {
        background-position: 50px -1090px;
    }

    .show-map-holder-data.show-airplane {
        background-position: 50px -1630px;
    }

    .show-map-holder-data {
        background-position: 50px -550px;
    }

    .anreise-txt-area {
        width: 250px;
    }


}

.portal-welcome .headline-blue,
.portal-welcome .headline-red,
.btn-portal-slider-wrapper {
    transform: translateX(0);
    opacity: 1;
}


/* ----------------------- 0 - 768px ----------------------- */
@media (max-width: 768px) {

	.ui-widget{
		font-size: 1.0em;
	}

	.ecommerce-fluid-container .container{
		padding: 0;
	}

    #content-portal{
        margin-top: 50px;
    }

    .portal-welcome h1 {
        font-size: 36px;
    }

	.max-height-100p,
    .portal-anreise,
    .portal-slider .image-holder,
    .portal-anreise .image-holder{
        height: 350px;
    }

	.portal-slider,
    .portal-live-info .image-holder,
    .portal-live-info{
        height: auto;
    }

    .portal-headline-tipps {
        margin-top: 0px;
    }

    .img-banner-animation {
        top: 78%;
    }

    .inspiration-banner {
        height: 24px;
    }

    .inspiration-banner h1 {
        font-size: 16px;
        margin-left: 25px;
        margin-top: 8px;
        padding-right: 14px;
    }

    .label-right .inspiration-banner h1 {
        margin-left: 12px;
    }

    .ecommerce-content:after,
    .tipps-teaser-tipp:after{
        height: auto;
    }

    .ecommerce-content.verlauf-white:after,
    .tipps-teaser-tipp.verlauf-white:after{
        transform: none;
        box-shadow: inset 0 -76px 30px -30px rgba(255,255,255,.9);
    }

	.ecommerce-headline{
		font-size: 20px;
        bottom: 5px;
	}

    .ecommerce-category{
	    font-size: 12px;
	    font-family: RobotoBold, Roboto;
        bottom: 30px;
    }

	.ecommerce-category:before{
	    position: relative;
	    content: "\E002";
	    font-family: iconfont;
	    font-size: 7px;
	    left: -1px;
    }
    .portal-ecommerce-teaser{
	    margin-top: auto;
    }

    .nav-live-info-mobile .col-xs-6{
          width: 185px;
      }

    .bergerlebnisse-slider .slick-next,
    .bergerlebnisse-slider .slick-prev{
        height: 60px;
        width: 20px;
        top: 160px;
    }

    .bergerlebnisse-slider .slick-prev{
        left: -10px;
    }

    .bergerlebnisse-slider .slick-next{
        right: -10px;
    }
}

/* ----------------------- 0 - 600px ----------------------- */

@media (max-width: 600px) {

    .portal-headline-bergerlebnisse h1,
    .portal-headline-tipps h1{
        font-size: 40px;
    }

    .portal-headline-bergerlebnisse h3 {
        font-size: 20px;
    }
}

@media (max-width: 400px) {
    .nav-live-info-mobile .col-xs-6{
        width: 165px;
    }
}

@media (max-width: 360px) {


    .nav-live-info-mobile .col-xs-6{
        width: 160px;
    }
}

@media (max-width: 340px) {

    .nav-live-info-mobile .col-xs-6{
        width: 140px;
    }
}

/* ----------------------- 0 - 400px ----------------------- */
@media screen and (max-width: 400px){
    .tipps-teaser-headline {
        font-size: 28px;
    }
}



/* -------------------------------------------- Salvattore Responsive Media Queries ------------------------------------------------- */

/* ----------------------- 0 - 480px ----------------------- */
@media screen and (max-width: 480px){
    #grid[data-columns]::before {
        content: '1 .column.size-1of1';
    }
}

/* ----------------------- 480 - 768px ----------------------- */
@media screen and (min-width: 481px) and (max-width: 768px) {
    #grid[data-columns]::before {
        content: '2 .column.size-1of2';
    }
}

/* ----------------------- 769 - xxxx ----------------------- */
@media screen and (min-width: 769px) {
    #grid[data-columns]::before {
        content: '3 .column.size-1of3';
    }
}

