@font-face {
  font-family: "iconfont";
  src: url('/static/icons/font/iconfont.eot?#iefix') format('eot'),
    url('/static/icons/font/iconfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.icon {
  display: inline-block;
  font-family: "iconfont";
  speak: none;
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}

.icon-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.icon-2x { font-size: 2em; }
.icon-3x { font-size: 3em; }
.icon-4x { font-size: 4em; }
.icon-5x { font-size: 5em; }
.icon-fw {
  width: 1.2857142857142858em;
  text-align: center;
}

.icon-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.icon-ul > li {
  position: relative;
}
.icon-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.icon-li.icon-lg {
  left: -1.85714286em;
}

.icon-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.icon-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.icon-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.icon-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.icon-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .icon-rotate-90,
:root .icon-rotate-180,
:root .icon-rotate-270,
:root .icon-flip-horizontal,
:root .icon-flip-vertical {
  filter: none;
}

.icon-info:before { content: "\E03F" }
.icon-login:before { content: "\E040" }
.icon-airplane:before { content: "\E001" }
.icon-arrow:before { content: "\E002" }
.icon-calendar:before { content: "\E003" }
.icon-car:before { content: "\E004" }
.icon-check-mark:before { content: "\E005" }
.icon-clock:before { content: "\E006" }
.icon-close:before { content: "\E007" }
.icon-comments:before { content: "\E008" }
.icon-elements:before { content: "\E009" }
.icon-gal-info:before { content: "\E00A" }
.icon-img-zoom:before { content: "\E00B" }
.icon-label:before { content: "\E00C" }
.icon-list:before { content: "\E00D" }
.icon-locator-pin:before { content: "\E00E" }
.icon-locator:before { content: "\E00F" }
.icon-mail:before { content: "\E010" }
.icon-more:before { content: "\E011" }
.icon-nav-cablecar:before { content: "\E012" }
.icon-nav-divider:before { content: "\E013" }
.icon-nav-lang:before { content: "\E014" }
.icon-nav-mobile-menu:before { content: "\E015" }
.icon-nav-search:before { content: "\E016" }
.icon-nav-shop:before { content: "\E017" }
.icon-nav-slopes:before { content: "\E018" }
.icon-nav-snow:before { content: "\E019" }
.icon-nav-webcams:before { content: "\E01A" }
.icon-pax:before { content: "\E01B" }
.icon-phone:before { content: "\E01C" }
.icon-simo-sign:before { content: "\E01D" }
.icon-social-facebook:before { content: "\E01E" }
.icon-social-googleplus:before { content: "\E01F" }
.icon-social-rss:before { content: "\E020" }
.icon-social-twitter:before { content: "\E021" }
.icon-social-youtube:before { content: "\E022" }
.icon-status-closed:before { content: "\E023" }
.icon-status-opened:before { content: "\E024" }
.icon-status-waiting:before { content: "\E025" }
.icon-temp:before { content: "\E026" }
.icon-wind:before { content: "\E027" }
.icon-zoom-in:before { content: "\E028" }
.icon-zoom-out:before { content: "\E029" }
.icon-snow-mountain:before { content: "\E02A" }
.icon-snow-arrow:before { content: "\E02B" }
.icon-scorpion:before { content: "\E02C" }
.icon-sun:before { content: "\E02D" }
.icon-delete:before { content: "\E02E" }
.icon-edit:before { content: "\E02F" }
.icon-error:before { content: "\E030" }
.icon-cart:before { content: "\E031" }
.icon-berg:before { content: "\E032" }
.icon-pfeil_berg:before { content: "\E033" }
.icon-pfeil_tal:before { content: "\E034" }
.icon-lifte:before { content: "\E035" }
.icon-status-601:before { content: "\E036" }
.icon-status-603:before { content: "\E037" }
.icon-status-606:before { content: "\E038" }
.icon-status-608:before { content: "\E039" }
.icon-status-609:before { content: "\E03A" }
.icon-status-612:before { content: "\E03B" }
.icon-status-614:before { content: "\E03C" }
.icon-status-607:before { content: "\E03D" }
.icon-status-617:before { content: "\E03E" }
.icon-status-703:before { content: "\E041" }
.icon-status-706:before { content: "\E043" }
.icon-status-709:before { content: "\E045" }
.icon-status-712:before { content: "\E047" }
.icon-status-717:before { content: "\E049" }
.icon-status-724:before { content: "\E04B" }
.icon-status-604:before { content: "\E050" }
.icon-status-610:before { content: "\E051" }
.icon-pinterest:before { content: "\E060" }
.icon-instagram:before { content: "\E061" }