.bg-info {
    background: #f2f2f3;
}

/*.text-default.text-default { color: #333333; }*/
.text-primary { color: #0064aa; }
a.text-primary:hover { color: #0079ce; }
.text-secondary { color: #0064aa; }
.text-success { color: #00aa24; }
.text-muted { color: #666666; }


/*Typo*/
strong,
.strong.strong {
    font-family: RobotoBold, RobotoCondensedBold, sans-serif;
    font-weight: normal;
}
.font-default {
    font-weight: normal;
}

.text-small {
    font-size: .875em;
}
.text-smaller {
    font-size: .75em;
}

.img-full-width {
    width: 100%;
}

.text-no-break {
    white-space: nowrap;
}
/*Margins & Paddings*/
.no-margin.no-margin {
    margin: 0;
}
.mb10 {
    margin-bottom: 10px;
}
.mb20 {
    margin-bottom: 20px;
}
.mb40 {
    margin-bottom: 40px;
}
.mb60 {
    margin-bottom: 40px;
}
@media screen and (min-width: 768px) {
    .mb60 {
        margin-bottom: 60px;
    }
}

.no-padding.no-padding {
    padding: 0;
}

@media screen and (max-width: 767px) {
    .mb30-xs {
        margin-bottom: 30px;
    }
}

/*Font size*/
.fz20.fz20 {
    font-size: 20px;

}

.fz16{
    font-size: 16px;
}

/*Display*/
.d-ib {
    display: inline-block;
}

/*Form*/
.form-control--default,
.form-control--default[readonly] {
    height: 50px;
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 16px;
    border-color: #e6e6e6;
    box-shadow: inset 0 0 25px rgba(0, 0, 0, .05);
    color: inherit;
    border-radius: 0;
    background: #FFFFFF;
}
.form-control-wrapper {
    position: relative;
}
.form-control-wrapper__icon {
    position: absolute;
    top: 0;
    right: 20px;
    bottom: 0;
    line-height: 50px;
    vertical-align: middle;
    pointer-events: none;
}
.form-control-wrapper>.form-control {
    padding-right: 40px;
}
.control-label.control-label--default {
    font-family: RobotoCondensedLight, sans-serif;
    font-weight: normal;
    text-align: left;
}
@media screen and (min-width: 768px) {
    .form-horizontal .control-label--default {
        padding-top: 15px;
    }

    .control-label--default > .control-label--default {
        padding-top: 0;
    }
}

/*Row gutter widths*/
.row--gutter-width-18.row--gutter-width-18 {
    margin-left: -9px;
    margin-right: -9px;
}
.row--gutter-width-18 > .col {
    padding-left: 9px;
    padding-right: 9px;
}
.row--gutter-width-10 {
    margin-left: -5px;
    margin-right: -5px;
}
.row--gutter-width-10 > .col {
    padding-left: 5px;
    padding-right: 5px;
}
.row--gutter-width-0 {
    margin-left: 0;
    margin-right: 0;
}
.row--gutter-width-0 > .col {
    padding-left: 0;
    padding-right: 0;
}

@media screen and (max-width: 767px) {
    .row--gutter-width-0-xs {
        margin-left: 0;
        margin-right: 0;
    }
    .row--gutter-width-0-xs > .col {
        padding-left: 0;
        padding-right: 0;
    }
}

/*Parsley*/
.parsley-errors-list {
    list-style-type: none;
    padding-left: 0;
    color: #0064aa;
}