:root {
    --color-text-primary: #333;
    --color-text-secondary: rgba(53, 3, 0, .54);
    --color-dividers: rgba(53, 3, 0, .12);
    --color-accent: #0064aa;
    --color-info: #D9EDF7;
}
.webshop-header .navbar-default > .container-fluid {
    overflow: visible;
}
.webshop-header__dropdown {
    background: #ffffff;
    border: 1px solid #e6e6e6;
    border-bottom: 1px solid #0064aa;
    border-top: none;
    display: none;
    left: 0;
    padding: 1em;
    position: absolute;
    top: 96%;
    width: 200px;
    z-index: 5;
}

.navbar-nav .has-dropdown:hover .is-dropdown{
    display: block;
}

.webshop-header__dropdown li>a{
    font-size: 14px;
}

.webshop-header__dropdown li>a:before{
    font-size: 14px;
}

.webshop-header__login-form input{
    min-width: 350px;
}
.webshop-header__login-form .form-control {
    color: #555;
}
.webshop-header__login-form .btn {
    display: block;
    margin-top: 1em;
}

.webshop-header__dropdown ul li a {
    color:  #333;
    position: relative;
    text-decoration: none;
}

.webshop-header__dropdown ul {
    list-style-type: none;
    padding-left: 25px;
}

.webshop-header__dropdown ul li a:before {
    content: '\E002';
    position: absolute;
    left: -20px;
    font-family: iconfont;
    font-size: 12px;
    top: 2px;
    padding-right: 15px;
}

.webshop-header__dropdown ul li a:hover {
    color: red;
}

.webshop-header #navbar-shop li.is-open > .webshop-header__dropdown,
.webshop-header #navbar-shop li:hover > .webshop-header__dropdown {
    display: block;
}
.registration-type-form {
    border: 1px solid var(--color-dividers);
}
.registration-type-form legend {
    left: -999em;
    position: absolute;
}
.registration-type-form fieldset {
    border-top: 1px solid var(--color-dividers);
    border-bottom: 1px solid var(--color-dividers);
    padding: 2em 0;
}
.registration-type-form fieldset .form-group {
    padding: 0 2em;
}
.registration-type-form__link {
    color: var(--color-text-secondary);
    margin-left: .75em;
    padding: 13px 0;
}
.registration-type-form__link:hover {
    color: var(--color-accent);
}
.registration-type-form .radio {
    font-size: 20px;
    padding: 10px;
    margin: 0;
}
.registration-type-form .radio:last-child {
    border-top: 1px solid rgba(53, 3, 0, .12);
}
.registration-type-form .radio label {
    font-weight: bold;
    font-family: Roboto, Arial, sans-serif;
}
.registration-type-form .radio input {
    height: 1.25em;
}

.terms-and-conditions input[type=checkbox]+label {
    padding-left: 0;
}
.terms-and-conditions input[type=checkbox]:checked+label:after {
    left: 0;
}
.terms-and-conditions .form-group {
    margin-left: 0;
    margin-right: 0;
}
.terms-and-conditions a + div {
    margin-top: 15px;
}

.form-info {
    background: var(--color-info);
    margin-bottom: 2em;
    padding: 1em;
}
.form-info__text {
    font-weight: bold;
}
.form-info__btn {
    margin-right: 1em;
}

.registration .nav-progress {
    margin-bottom: 2em;
}
.registration h2 {
    display: inline-block;
    margin-right: .5em;
}

.billing-address__list {
    list-style: none;
    padding: 0;
}

.address-form {
    display: none;
}
.fixed-address__edit-button,
.fixed-address__edit-button:hover
{
    color: #333;
}
.btn-abort {
    border-radius: 0;
    font-size: 20px;
    font-family: RobotoCondensedRegular,sans-serif;
    padding: 12px 18px;
    border: 0;
}
.form-group--buttons {
    margin-top: 15px;
}

.registration a.icon {
    color: #666;
}

.registration a.icon:hover {
    color: #aaa;
    text-decoration: none;
}

@media screen and (min-width: 768px) {
    .registration-type-form fieldset {
        padding: 2em;
    }
}
