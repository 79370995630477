/* ----------------------------------------- SIDEBAR ----------------------------------------- */

#js-sidebar .container{
	width: auto;
}
#js-sidebar .col-sm-3{
	width: 100% !important;
}

#js-sidebar .active > ul{
	display: block;
}

#js-sidebar .subnav .dropdown-navigation > ul > li > a,
#js-sidebar .subnav .dropdown-navigation.nav-wide .col-sm-3 > ul > li > a,
#js-sidebar .subnav > li > a,
#js-sidebar .subnav > li > ul > li > a{
    display: block;
}

#js-sidebar .subnav .dropdown-navigation > ul > li > a:hover,
#js-sidebar .subnav .dropdown-navigation.nav-wide .col-sm-3 > ul > li > a:hover,
#js-sidebar .subnav > li > a:hover,
#js-sidebar .subnav > li > ul > li > a:hover{
    color: #0064aa;
    text-decoration: none;
}

#js-sidebar .subnav .dropdown-navigation.nav-wide .col-sm-3  > ul > li.active> a,
#js-sidebar .subnav .dropdown-navigation>ul>li.active>a{
    color: #0064aa;
}


#js-sidebar .subnav > li.active > a{
    font-family: Oswald-Regular, sans-serif;
    font-size: 24px;
    text-transform: uppercase;
}

#js-sidebar .subnav > li.active > a:before{

    position: absolute;
    left: -12px;
    top: 12px;
    content: '\e002';
    font-family: 'iconfont';

    font-size: 10px;
}

#js-sidebar .subnav > li.active>  a:hover{
    background: transparent;
}

#js-sidebar .subnav > li.active > ul > li{
    font-family: RobotoLight, sans-serif;
    font-size: 16px;
}

#js-sidebar .subnav > li > div > ul > li,
#js-sidebar .subnav .nav-wide .col-sm-3 > ul > li,
#js-sidebar .subnav > li.active > ul > li,
#js-sidebar .subnav > li.active > ul > li > div > ul > li{
    list-style: none;
}


#js-sidebar .subnav > li > div > ul > li > a:before,
#js-sidebar .subnav .nav-wide .col-sm-3 > ul > li > a:before{
    content: '\e002';
    font-family: 'iconfont';
    font-size: 9px;

    position: absolute;
    margin-top: 0px;
    margin-left: -2px;
}


#js-sidebar .subnav > li.active > ul > li.active > a:before,
#js-sidebar .subnav > li.active > ul > li > div > ul > li > a:before,
#js-sidebar .subnav > li.active > ul > li > a:before{
    content: '\e002';
    font-family: 'iconfont';
    font-size: 8px;

    position: absolute;
    margin-top: 6px;
    margin-left: -9px;
}

#js-sidebar .subnav .nav-wide ul{
    padding-left: 0;
}

#js-sidebar .subnav ul{
    padding-left: 14px;
}

#js-sidebar .col-sm-4{
    width: 100%;
}

#js-sidebar .col-sm-4 ul>li.active>a{
    color: #0064aa;
}
#js-sidebar .subnav .nav-wide ul li>ul{
    padding-left: 14px;
}

#js-sidebar .subnav .nav-wide ul li>ul>li:before{
    display: none;
}

.subnav .dropdown-navigation{
	display: block;
}
.subnav .dropdown-navigation .container{
    padding-left: 0;
}

.subnav ul ul{
    display: none;
}

.subnav li.active > div > ul{
    display: block;
}

.subnav ul > li > ul > li:before{
    position: absolute;
    left: 74px;
}

.subnav > ul > li.active > ul > li.active > ul > li:before{
    position: absolute;
    left: 115px;
}

.subnav > ul > li.active a:before,
.subnav > ul > li > a:before
{
    position: absolute;
    left: 30px;
}

.subnav > ul > li.active,
.subnav ul > li > ul > li,
.subnav > ul > li{
    padding-bottom: 5px;
}

.subnav > ul > li > a:before,
.subnav ul > li > ul > li:before
{
    content: '\e002';
    font-family: 'iconfont';
    margin-left: 13px;
    margin-top: 4px;
    font-size: 10px;
}

.subnav ul > li > ul > li:before {
    margin-left: 9px;
    margin-top: 5px;
    font-size: 9px;
}

.subnav > ul > li,
.subnav ul > li > ul > li{
    list-style: none;
}

.subnav > ul > li.active > a:hover,
.subnav > ul > li a:hover,
.subnav > ul > li.active > a,
.subnav > ul > li a{
   text-decoration: none;

}
.subnav > ul > li.active > ul > li.active ul,
.subnav > ul > li.active > ul{
    display: block;

}
.subnav  ul > li.active a,
.subnav  ul > li a{
    color: #666666;
    font-family: RobotoLight;
    font-size: 16px;
}

.subnav > ul > li{
    color: #666666;
}

.subnav > ul > li.active > a{
    font-family: RobotoBold,sans-serif;
    font-size: 18px;
}

.subnav > ul > li > a{
    font-family: RobotoBold, sans-serif;
    font-size: 18px;
}

.subnav li ul li {
    -webkit-transition:max-height 500ms ease;
    -moz-transition:max-height 500ms ease;
    -o-transition:max-height 500ms ease;
    transition:max-height 500ms ease;
    list-style: none;
}

@media (min-width: 768px) and (max-width: 991px) {
    .subnav > ul > li.active > ul a{
        font-size: 15px;
    }

    .subnav  ul > li a,
    .subnav  ul > li.active a{
        font-size: 17px;
    }

}
