

.dropdown-navigation.nav-wide .col-xs-4 > ul > li {
    margin-bottom: 20px;
}

.nav-custom .navbar-nav>li>a{
    color: #333333;
    font-size: 20px;
    padding-top: 16px;
    padding-bottom: 20px;
}

.nav-custom-items li{
    position: static;
}

.nav-custom-items .dropdown-navigation{
    border-left: 1px solid #e9e9e9;
    border-right: 1px solid #e9e9e9;
}

.nav-custom-items > li > a:after{
    content: '/';
    position: absolute;
    right: -3px;
    top: 50%;
    margin-top: -10px;
    color: #a2a2a2;
}
.nav-custom-items > li > a:hover:after{
    color: #a2a2a2;
}
.nav-custom-items > li:last-child > a:after{
    content: '';
}

.nav-custom-items li .dropdown-navigation {
    display: block;
    visibility: hidden;
    background-color: #fff;
    padding: 20px;
    position: absolute;
    min-width: 250px;
    margin: 0;
    transform: translateY(-30px);
    transform-origin: top center;
    opacity: 0;
    transition: visibility 0.01ms 160ms, transform 130ms ease-out, opacity 160ms ease-out;
    z-index: -1;
}
.nav-custom-items li:hover .dropdown-navigation,
.nav-custom-items>li.is-open>.dropdown-navigation{
    transition: visibility 0.01ms, transform 130ms ease-out, opacity 100ms ease-out;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
    z-index: 1;
}
.nav-custom-items li:hover .dropdown-navigation ul .dropdown-navigation{
	display: none;
}

.nav-custom-items li:hover .dropdown-navigation.nav-wide{
    left: 0;
    right: 0;
    padding-top: 50px;
}

.nav-custom-items li .dropdown-navigation.nav-wide .col-xs-12.custom-col ul > li > ul > li > div{
    display: none;
}

.nav-custom-items li ul{
    list-style: none;
    margin: 0 0 0 15px;
    padding: 0;
}
.nav-custom-items li > ul{
    margin-bottom: 20px;
}


.nav-custom-items li ul > li a:hover:before,
#js-sidebar .subnav > li.active > a:hover:before,
.nav-custom-items .nav-wide .custom-col>ul>li>a:hover:before{
    left: -10px;
    transition: all .1s linear;
}

#js-sidebar .subnav > li > div > ul > li > a:hover:before{
    left: -8px;
    transition: all .1s linear;
}

#js-sidebar .subnav > li > ul > li > a:hover:before{
    left: 1px;
    transition: all .1s linear;
}


.nav-custom-items li ul > li{
    padding: 3px 0;
}

.nav-custom-items .nav-wide .custom-col > ul > li > a{
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 24px;
    text-decoration: none;
    color: #0064aa;
}

.nav-custom-items .nav-wide .custom-col > ul > li > ul > li a:hover{
    color: #0064aa;
}

.nav-custom-items .nav-wide .custom-col > ul > li > a:hover,
.nav-custom-items .dropdown-navigation ul > li > a:hover{
    color: #ff0017;
}

.nav-custom-items .nav-wide .custom-col > ul > li{
    margin-top: 10px;
}

.nav-custom-items li ul > li a{
    position: relative;
    text-decoration: none;
    color: #333;
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 18px;
}

.nav-custom-items .nav-wide .custom-col > ul > li > ul{
   margin-top: 5px;
}

.nav-custom-items li ul > li ul > li a:before,
.nav-custom-items .dropdown-navigation ul > li > a:before{
    font-size: 9px;
    top: 0px;
}

.nav-custom-items li ul > li a:before,
.nav-custom-items .nav-wide .custom-col > ul > li > a:before{
    content: '\E002';
    font-size: 11px;
    font-family: 'iconfont';
    position: absolute;
    left: -12px;
    top: 4px;
    width: 20px;
    height: 20px;
    list-style: none;
}

/* nav-item containing a snippet*/
.nav-custom-items li ul > li.snippet a:before {
    display: none;
}


.nav-custom-items li ul>li.active>a {
    color: #0064aa;
}

/*Shop Nav Dropdown*/
.shop-dropdown-container {
    position: absolute;
    top: 60px;
    left: 0;
    padding: 50px 20px 20px 20px;
    background: #fff;
    width: 100%;
    display: none;
    border-bottom: 1px solid #0064aa;
}
.shop-dropdown-image {
    margin-bottom: 20px;
}
.shop-dropdown-headline, .shop-dropdown-link {
    font-family: RobotoLight, sans-serif;
    font-weight: 300;
    font-size: 20px;
    line-height: 22px;
    color: #004678;
}
.shop-dropdown-link a{
    font-size: 16px;
    color: #0064aa;
    text-decoration: none;
    position: relative;
    padding-left: 10px;
}
.shop-dropdown-link a:before {
    content: '\E002';
    font-size: 11px;
    font-family: iconfont;
    position: absolute;
    left: 0;
    top: 1px;
    transition: all .1s linear;
}
.shop-dropdown-link a:hover:before {
    left: 2px;
}
.shop-dropdown-container {
    display: block;
    visibility: hidden;
    transform: translateY(-30px);
    opacity: 0;
    z-index: -1;
    transition: visibility 0.01ms 160ms, transform 130ms ease-out, opacity 160ms ease-out;

}
.navbar-collapse .right-skew-container:hover .shop-dropdown-container ,
.navbar-collapse .right-skew-container.is-open .shop-dropdown-container {
    transition: visibility 0.01ms, transform 130ms ease-out, opacity 100ms ease-out;
    visibility: visible;
    opacity: 1;
    transform: translateY(0);
}


/* breadcrumb */
.custom-breadcrumb .breadcrumb{
    padding-top: 23px;
    background-color: #fff;
    margin-left: 270px;
	font-family: RobotoLight, sans-serif;
    font-size: 15px;
}

.custom-breadcrumb .breadcrumb a{
    color: #000;
}

.custom-breadcrumb .breadcrumb a:hover{
    color: #0064aa;
    text-decoration: none;
}

.breadcrumb > li + li:before{
    content: '\e002';
    font-family: 'iconfont';
    font-size: 8px;
    padding-right: 9px;
    padding-left: 6px;
}

/* --- Avalanche Square --- */

.state-1{
    border-color: rgb(204,255,102);
}
.state-2{
    border-color: rgb(255,255,0);
}
.state-3{
    border-color: rgb(255,153,0);
}
.state-4,
.state-5{
    border-color: rgb(255,0,0);
}

.state-1:hover{
    box-shadow: 0 0 10px 0px #CCFF66;
}
.state-2:hover{
    box-shadow: 0 0 10px 0px #FFFF00;
}
.state-3:hover{
    box-shadow: 0 0 10px 0px #f90;
}
.state-4:hover,
.state-5:hover{
    box-shadow: 0 0 10px 0px #FF0000;
}


.avalanche-square {
    top:-5px;
    left: 8px;
    width: 17px;
    height: 17px;
    border-style: solid;
    border-width: 1px;

    transform: rotate(45deg);
    position: relative;
    display: inline-block;
}

.avalanche-square:hover {
    /* transform: rotate(405deg); */
    transition: all .1s linear;

}

.avalanche-square .number{
    transform: rotate(-45deg);
    position: relative;
    top: -6px;
    left: 3px;
    font-size: 12px;
    font-weight: 700;
}

.navbar-nav.navbar-right > li > a{
    padding-top: 14px;
    padding-bottom: 11px;
}
/* ------------------------ Media QUERY ------------------------------ */

@media (min-width: 768px) and (max-width: 1199px) {
    .nav-custom-items{
        padding-left: 210px;
        text-align: left;
    }
    #main-logo img{
        height: 50px;
        width: auto;

        margin-top: 3px;
    }
    #main-logo{
        height: 60px;
        width: auto;
    }

    .navbar-nav > li > a{
        font-size: 12px;
    }

    .logo-shadow:before{
        left: 170px;
    }

    .img-logo{
        margin: 17px 40px;
    }

    .nav-box{
        position: relative;
        left: 30px;
        height: 60px;
    }

    .nav-box > li:first-child {
        padding-left: 10px;
    }
    .box-nav-skew span{
        font-size: 13px;
    }

    .avalanche-square .number{
        left: 2px;
    }
}
@media (max-width: 768px) {
    .logo-shadow:before{
        display: none;
    }

    .nav-brand-custom{
        height: 50px;
        width: 180px;
    }

    .img-logo{
        width: 105px;
        margin: 8px 42px;
    }

    .logo-shadow:before {
        display: block;
        height: 50px;
        left: 129px;
    }

    .mobile-header-link:last-child {
        margin-right: 15px;
    }

}

@media (max-width: 375px) {

    .nav-brand-custom{
        height: 50px;
        width: 140px;
    }

    .img-logo{
        width: 52px;
        margin: 11px 51px;
    }

    .logo-shadow:before {
        display: block;
        height: 50px;
        left: 107px;
    }

    .mobile-header-link:last-child {
        margin-right: 5px;
    }
}

@media screen and (max-width: 1400px) {
    .nav-custom .navbar-nav>li>a {
        padding-left: 15px;
        padding-right: 15px;
    }

}

@media screen and (min-width: 768px) and (max-width: 1300px){
    .nav-custom .navbar-nav>li>a{
        font-size: 16px;
        padding: 15px 12px;
    }
    .nav-custom .navbar-nav>li>a.move-nav-box-text{
        padding: 15px 5px;
    }
}

@media (max-width: 900px) {
    .language-search{
        display: none;
    }
}

@media screen and (min-width: 992px ) and (max-width: 1105px){
    /*.nav-custom-items{*/
        /*margin-left: 270px;*/
    /*}*/

    /*.nav-custom .navbar-nav>li>a {*/
        /*font-size: 15px;*/
        /*padding: 15px 8px;*/
    /*}*/
}

@media (max-width: 1190px){
    .nav-box{
        position: relative;
        left: 30px;
        height: 60px;
    }

    .nav-box > li:first-child {
        padding-left: 10px;
    }
    .box-nav-skew span{
        font-size: 15px;
    }

}
@media (max-width: 960px) {

    .nav-box{
        position: relative;
        left: 30px;
        height: 60px;
    }
    .nav-box > li:first-child {
        padding-left: 10px;
    }
    .box-nav-skew span{
        font-size: 14px;
    }

    .navbar-nav > li > a{
        font-size: 14px;
    }

}

@media (max-width: 915px) {
    .nav-box{
        position: relative;
        left: 30px;
        height: 60px;
    }
    .nav-box > li:first-child {
        padding-left: 10px;
    }
    .box-nav-skew span{
        font-size: 12px;
    }

    .navbar-nav > li > a{
        font-size: 12px;
    }

}



@-moz-document url-prefix() {
    .avalanche-square .number {
        top: -5px;
        left: 2px;
    }
}
