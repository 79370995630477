:root {
    --color-text-primary: #333;
    --color-text-secondary: rgba(53, 3, 0, .54);
    --color-dividers: rgba(53, 3, 0, .12);
    --color-accent: #0064aa;
    --color-info: #D9EDF7;
}

html, body {
    position: relative;
    overflow-x: hidden;
}

/*general*/
.webshop-content {
    margin-top: 40px;
}

.shop-welcome-text {
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 20px;
    padding-top: 19px;
    margin-left: 34.5%;
    text-align: left;
}

/*Navbar Shop*/
#navbar-shop {
    background: none;
    position: relative;
    left: auto;
    height: initial;
    padding-left: 0;
}
#navbar-shop .icon {
    margin-right: .5em;
    position: relative;
    top: 2px;
}
#navbar-shop > li{
    background: none;
    display: inline-block;
    float: none;
    margin-right: -.5em;
    padding-left: 2em;
    height: 100%;
    padding-top: .25em;
}
#navbar-shop > li:last-child {
    margin-right: 0;
    padding-right: 5px;
}
#navbar-shop > li:before {
    box-shadow: -10px 0 10px 1px rgba(136, 136, 136, .3);
    bottom: 0;
    content: '';
    display: block;
    height: 100%;
    left: 0;
    right: 0;
    position: absolute;
    transform: skew(-30deg);
    transform-origin: bottom center;
    width: 100%;
    z-index: 0;
}
#navbar-shop > li a {
    font-size: 17px;

}

#navbar-shop > li a:before{
    top: 4px;
}

#navbar-shop > li>a{
    padding-top: 10px;
    padding-bottom: 15px;
}
#navbar-shop > li:last-child a {
    margin: 0;
}
#navbar-shop > li:last-child:before {
    background: var(--color-accent);
}

.back-link {
    display: block;
    font-size: 16px;
}
.back-link .icon-arrow {
    margin-right: 10px;
}
@media screen and (min-width: 768px) {
    .back-link {
        float: right;
    }
}
.back-link:hover {
    text-decoration: none;
}
.back-link .icon {
    font-size: 9px;
}

.mobile-header-link {
    display: block;
    padding: 11px;
    width: 50px;
    text-align: center;
    font-family: RobotoCondensedBold, sans-serif;
    font-size: 20px;
}

.shopping-cart-mobile {
    width: 85px;
}

.shopping-cart-mobile .icon-cart {
    font-size: 18px;
    vertical-align: -2px;
}

.border-box {
    padding: 20px;
    border: 1px solid #e6e6e6;
}

/*security payment widget*/
.secure-payment-widget,
.secure-payment-widget .payment-additional-info{
    margin-top: 30px;
}
.secure-payment-widget:first-child {
    border-top: none;
    margin-top: 0;
    padding-top: 0;
}
.secure-payment-widget h2 {
    font-family: RobotoCondensedBold,sans-serif;
    font-size: 24px;
    margin-top: 0;
}
.secure-payment-widget .payment-additional-info h2 {
    font-size: 20px;
}


.webshop-content .btn .text-delete {
    display:none;
}
@media screen and (max-width: 767px) {
    .webshop-content .panel-title .btn-link {
        padding-left:0;
    }
    .webshop-content .btn .icon-delete {
        float:left;
        margin:3px 10px 0 0;
    }
    .webshop-content .btn .text-delete {
        display:inline;
        font-size:12px;
    }
}

.webshop-content .btn-toolbar .btn {
    margin-bottom: 10px;
}

/*collapse-link */
.collapse-link {
    display: block;
    margin-bottom: 10px;
    margin-left: 3px;
}
.collapse-link:hover {
    text-decoration: none;
}
.collapse-link__collapsed,
.collapse-link.collapsed .collapse-link__expanded {
    display: none;
}
.collapse-link.collapsed .collapse-link__collapsed {
    display: block;
}
.collapse-link .icon {
    font-size: .8em;
    margin-right: 8px;
}

/*Webshop Teaser*/
.webshop-teaser {
    font-size: 15px;
    margin-bottom: 20px;
    border-top: 1px solid #e3e3e3;
    padding-top: 20px;
}

@media screen and (min-width: 768px) {
    .webshop-teaser {
        border-top: none;
        padding-top: 0;
    }
}
@media screen and (min-width: 1200px) {
    .webshop-teaser {
        font-size: 15px;
    }
}
@media screen and (min-width: 768px) {
    .webshop-teaser:hover {
        box-shadow: 0 0 5px rgba(0,0,0,.2);
    }
    .webshop-teaser__body {
        min-height: 200px;
        padding: 11px 15px;
        background: #f5f5f5;
        border: 1px solid #d9d9d9;
        border-top: none;
    }
}

@media screen and (min-width: 1200px) {
    .webshop-teaser__body {
        padding: 16px 20px;
    }
}
.webshop-teaser__hl {
    margin: 0 0 5px;
    font-size: 20px;
    font-family: Oswald-Regular,sans-serif;
}

@media screen and (min-width: 1200px) {
    .webshop-teaser__hl {
        font-size: 24px;
    }
}
.webshop-teaser__subline {
    font-family: RobotoCondensedLight, sans-serif;
    margin-bottom: 5px;
}
.webshop-teaser__link>.icon {
    font-size: 11px;
}
@media screen and (max-width: 767px) {
    .webshop-teaser .embed-responsive {
        margin-bottom:15px;
    }
}


/*panel-webshop*/
.panel-group .panel-webshop {
    border-color: #e6e6e6;
    border-radius: 0;
    box-shadow: none;
    margin-bottom: 17px;
}
.panel-webshop.panel-webshop--small {
    margin-bottom: 0;
    border-top: 1px solid #e6e6e6;
}

.panel-webshop .panel-heading {
    padding: 0;
}

.panel-webshop .panel-title {
    display: block;
    position: relative;
    background: #f2f2f3;
    padding: 17px 15px 13px 15px;
}

@media screen and (min-width: 768px) {
    .panel-webshop .panel-title {
        padding: 8px 26px 13px 26px;
    }
    .panel-webshop--small .panel-title {
        padding-top: 15px;
        padding-bottom: 13px;
    }
}
.panel-webshop--collapsable .panel-title {
    background: #f3f2f2;
}
.panel-webshop--collapsable .panel-title.collapsed {
    background-color: transparent;
}

.panel-webshop--collapsable .panel-title:hover {
    background: #f3f2f2;
    text-decoration: none;
    color: inherit;
}

.panel-webshop .panel-body {
    position: relative;
    border-top: 1px solid #e6e6e6;
    font-size: 17px;
    padding: 5px 15px;
}
@media screen and (min-width: 768px) {
    .panel-webshop .panel-body {
        padding: 27px;
    }
}
.panel-webshop .panel-body:first-child {
    border-top: none;
    padding-top: 0;
}
.panel-webshop .panel-body--indented,
.panel-webshop .panel-body--cart-form.is-submitted {
    border-top: none;
    padding-bottom: 0;
    padding-top: 0;
    margin-bottom: 20px;
}
.panel-webshop .panel-body--cart-form .nav-tabs{
    border: none;
}
.panel-webshop .panel-body--cart-form .nav-tabs li > a{
    padding: 0 10px;
}
.panel-webshop .panel-body--cart-form .nav-tabs li.active > a{
    border-radius: 4px;
    border-bottom-color: #ddd;
}

@media screen and (min-width: 768px) {
    .panel-webshop .panel-body--indented,
    .panel-webshop .panel-body--cart-form {
        padding-left: 50px;
    }
}

.panel-webshop--collapsable .panel-body:first-child {
    padding-top: 15px;
}

@media screen and (min-width: 768px) {
    .panel-webshop--collapsable .panel-body:first-child {
        padding-top: 27px;
    }
}

.panel-webshop--collapsable .panel-body:first-child:after {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: 27px;
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 16px 16px 0 16px;
    border-color: #f3f2f2 transparent transparent transparent;
}
.panel-webshop__headline.panel-webshop__headline {
    color: inherit;
    margin-top: 0;
    font-size: 18px;
    font-family: "RobotoBold", sans-serif;
    margin-bottom: 0;
}
.panel-webshop--small .panel-webshop__headline.panel-webshop__headline {
    padding-bottom: 10px;
}
@media screen and (min-width: 768px) {
    .panel-webshop__headline.panel-webshop__headline {
        font-size: 20px;
        padding-top: 5px;
        padding-bottom: 4px;
    }
}
.panel-webshop--small .panel-webshop__headline.panel-webshop__headline {
    font-size: 1em;
}
@media screen and (min-width: 768px) {
    .panel-webshop--small .panel-webshop__headline.panel-webshop__headline {
        padding: 0;
        line-height: inherit;
    }
}
.panel-webshop__label {
    line-height: 30px;
    font-family: "RobotoBold", sans-serif;
    margin-bottom: 0;
}
.panel-webshop__collapse-icon {
    margin-top: 10px;
}
.panel-webshop__collapse-icon .icon {
    font-size: 13px;
    margin-left: 5px;
    margin-right: 5px;
    transform: rotate(45deg);
    transition: transform 120ms;
}
.collapsed .panel-webshop__collapse-icon .icon {
    transform: rotate(0deg);
}
@media screen and (min-width: 768px) {
    .panel-webshop__collapse-icon {
        position: absolute;
        right: 30px;
        top: 50%;
        margin-top: -12px;
        padding: 0;
        width: auto;
    }
    .panel-webshop__collapse-icon .icon {
        font-size: 20px;
        margin-left: 0;
        margin-right: 0;
    }
}

/*Cart form*/
.cart-data-form {
    background: #fafafa;
}
.cart-data-form.is-submitted {
    background: none;
}
.cart-data-form.item-error {
    color: #a94442;
}

.cart-data-form__title {
    font-size: inherit;
    line-height: inherit;
    margin-top: 0;
    margin-bottom: 15px;
}

/*Ticket Table*/
@media screen and (min-width: 768px) {
    .product-table {
        font-size: 15px;
    }
}
@media screen and (min-width: 1200px) {
    .product-table {
        font-size: 17px;
    }
}

.pricing-rule{
    font-size: 14px;
    margin-bottom: 0;
}

.product-table__head {
    margin-bottom: 12px;
}
.product-table__item {
    border-bottom: 1px solid #e3e3e3;
    padding-top: 8px;
    padding-bottom: 15px;
}
.product-table__item:last-child {
    border-bottom: none;
}
@media screen and (min-width: 768px) {
    .product-table__item {
        border-bottom: none;
        margin-bottom: 0;
        padding-bottom: 0;
        padding-top: 8px;
        min-height: 52px;
    }
}
.product-table__item--with-border {
    border-top: 1px solid #e6e6e6;
    border-bottom: none;
    padding-top: 13px;

    margin-bottom: 5px;
    min-height: 0;
}

@media screen and (max-width: 767px) {
    .product-table__item-field {
        padding-top: 10px;
    }
    .product-table__item-field--highlight {
        font-family: RobotoBold, sans-serif;
    }
    .product-table__item-title {
        font-family: RobotoCondensedBold, sans-serif;
        font-size: 17px;
        margin-bottom: 10px;
    }
    .product-table__item-price,
    .product-table__item-value{
        text-align: center;
    }
}

@media screen and (min-width: 768px) {
    .product-table__item-price {
        text-align: right;
    }
}

.product-table__hr {
    margin: 15px -10px 18px;
}

/*Number Spinner*/
.number-spinner {
    width: 116px;
    display: inline-block;
}

@media screen and (min-width: 768px) {
    .number-spinner {
        display: block;
    }
}
.number-spinner__input {
    height: 35px;
    box-shadow: inset 0 0 25px rgba(0, 0, 0, .05);
    text-align: center;
    padding: 0 6px;

    -moz-appearance:textfield;
}
@media screen and (min-width: 768px) {
    .number-spinner__input {
        padding: 6px 12px;
    }
}
.number-spinner__input::-webkit-outer-spin-button,
.number-spinner__input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
.number-spinner__button {
    height: 35px;
    width: 35px;
    border-radius: 0;
    background: #e9e9e9;
    border-color: #e9e9e9;
    color: #999999;
    font-size: 28px;
    line-height: 1;
    padding: 0 0 4px;
    font-family: sans-serif;
}
.input-group-btn:first-child>.number-spinner__button {
    margin-right: 0;
}
.product-table__item .number-spinner {
    height: 32px;
    position: relative;
    top: -8px;
}

@media screen and (min-width: 768px) {
    .number-spinner {
        width: 136px;
    }
    .number-spinner__input {
        height: 40px;
    }
    .number-spinner__button {
        height: 40px;
        width: 40px;
    }
}


/*Cart table*/
.cart-table__head {
    margin-bottom: 10px;
    margin-top: 10px;
}
.cart-table h3 {
    line-height: inherit;
}
.cart-table__item {
    margin-top: 10px;
    margin-bottom: 10px;
}
.cart-table__item .js-cart-data-form__remove{
    padding: 0;
}
@media screen and (max-width: 767px) {
    .cart-table .nav-tabs {
        margin-bottom:10px;
    }
    .cart-table .nav-tabs li {
        margin-bottom:5px;
    }
}

/*Keycard form*/
.keycard-form__input {
    display: inline-block;
    text-align: center;
    width: 55px;
    padding: 6px;
    text-transform: uppercase;
}

.keycard-form__input--wide {
    width: 90px;
}

.js-cart-data-form__static__keycard{
    text-transform: uppercase;
}

/*Form-errors*/
@media screen and (max-width: 767px) {
    .form-errors {
        margin-top: 10px;
    }
}
@media screen and (min-width: 768px) {
    .form-errors .parsley-errors-list.filled {
        display: table;
        min-height: 40px;
        margin-bottom:0;
    }
    .form-errors .parsley-errors-list.filled > li {
        display: table-cell;
        vertical-align: middle;
    }
    .form-errors .parsley-errors-list.filled + .filled{
        display: none;
    }
}

/* form controls*/
.webshop-content .form-control {
    padding: 6px 8px;
}
.webshop-content .form-control-wrapper>select.form-control {
    padding-right:5px;
}
@media screen and (max-width: 767px){
    .webshop-content input[type=checkbox]:checked+label:after {
        left: 20px;
    }
}

/*payment labels*/

.payment-label {
    display: inline-block;
    width: 70px;
    height: 45px;
    background: #dddddd;
    overflow: hidden;
    text-indent: -999em;
    vertical-align: middle;
    background-size: 70px 45px;
    background-repeat: no-repeat;
}
label.payment-label {
    margin-left: 10px;
    margin-right: 15px;
}
.payment-label--visa {
    background-image: url(/static/img/webshop/payment-label/visa.png);
}
.payment-label--master-card {
    background-image: url(/static/img/webshop/payment-label/mastercard.png);
}
.payment-label--paypal {
    background-image: url(/static/img/webshop/payment-label/paypal.png);
}
.payment-label--sofort {
    background-image: url(/static/img/webshop/payment-label/sofort.png);
}
.payment-label--wirecard {
    background-image: url(/static/img/webshop/payment-label/wirecard.png);
    width: 150px;
    background-size: 150px 45px;
}
@media
    only screen and (-webkit-min-device-pixel-ratio: 2),
    only screen and (   min--moz-device-pixel-ratio: 2),
    only screen and (     -o-min-device-pixel-ratio: 2/1),
    only screen and (        min-device-pixel-ratio: 2),
    only screen and (                min-resolution: 192dpi),
    only screen and (                min-resolution: 2dppx) {


    .payment-label--visa {
        background-image: url(/static/img/webshop/payment-label/visa_retina.png);
        background-size: 70px;
    }
    .payment-label--master-card {
        background-image: url(/static/img/webshop/payment-label/mastercard_retina.png);
        background-size: 70px;
    }
    .payment-label--paypal {
        background-image: url(/static/img/webshop/payment-label/paypal_retina.png);
        background-size: 70px;
    }
    .payment-label--sofort {
        background-image: url(/static/img/webshop/payment-label/sofort_retina.png);
        background-size: 70px;

    }
    .payment-label--wirecard {
        background-image: url(/static/img/webshop/payment-label/wirecard_retina.png);
    }
}

.payment-label-grid {
    font-size: 0; /*remove whitespace*/
    margin-top: 15px;
}
.payment-label-grid .payment-label {
    margin-right: 10px;
    margin-bottom: 10px;
}

@media screen and (min-width: 768px) {
    .payment-label-grid {
        width: 160px;
    }
}

/* Webshop Footer*/
.footer-faq,
.footer-help-center {
    min-height: 197px;
    padding-top: 35px;
    padding-bottom: 35px;
}
.footer-faq h2,
.footer-help-center h2 {
    margin-top: 0;
    font-family: RobotoLight,sans-serif;
    font-weight: normal;
}
.footer-help-center {
    background: #f2f2f2;
}
.footer-faq__img-container {
    height: auto;
    position: relative;
    top: 2px;
}
.footer-faq__img {
    position: relative;
}
.footer-faq .footer-img-berg {
    top: 15px;
}

/*Footer Accordion*/

.panel-group .footer-panel+.footer-panel {
    margin-top: 0;
}
.footer-panel-group {
    margin-bottom: 0;
}

.footer-panel {
    box-shadow: none;
    border: none;
    background: #f2f2f2;
}
.footer-panel .panel-title {
    display: block;
    padding: 15px 30px;
    border-bottom: 1px solid #e3e3e3;

    background: rgb(217,217,217);
    background: linear-gradient(to right, rgba(217,217,217,1) 0%,rgba(242,242,242,1) 100%);
}
.footer-panel .panel-title:hover,
.footer-panel .panel-title:focus,
.footer-panel .panel-title:active {
    color: inherit;
    text-decoration: none;
}
.footer-panel .panel-title.collapsed {
    background: transparent;
    border-top-color: transparent;
}
.footer-panel .panel-title > h3 {
    font-size: 20px;
    font-family: RobotoCondensedBold, sans-serif;
    margin: 0;
}
.footer-panel .panel-body {
    padding-left: 30px;
    padding-right: 30px;
    border-bottom: 1px solid #e3e3e3;
}
.footer-panel__collapse-icon {
    font-size: 16px;
    transform: rotate(45deg);
    transition: transform 120ms;
}
.collapsed .footer-panel__collapse-icon {
    transform: rotate(0deg);
}

/*Nav Progress*/
.nav-progress {
    border: 1px solid #e6e6e6;
    overflow: hidden;
    background: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(242,242,242,1) 100%); /* W3C */
    font-family: "RobotoCondensedRegular", sans-serif;
    color: #999999;
}
.nav-progress__item.nav-progress__item {
    display: table-cell;
    width: 1%;
}
.nav-progress__link.nav-progress__link {
    display: block;
    color: inherit;
    text-align: center;
    margin-bottom: 0;
}
.nav-progress > li > .nav-progress__link {
    padding: 15px;
    text-indent: 15px;
}
.nav-progress > li > a:focus,
.nav-progress > li > a:active,
.nav-progress > li > a:hover {
    background: transparent;
    color: #333;
}

/*Active State*/
.nav-progress__item.active {
    position: relative;
    background: #f2f2f2;
    color: #333333;
    font-family: RobotoCondensedBold, sans-serif;
}
.nav-progress__item.active:before,
.nav-progress__item.active:after {
    content: '';
    position: absolute;
    top: 0;
    bottom:0;
    left: 100%;
    width: 30px;
    background: linear-gradient(to right, rgba(191,191,191,1) 0%,rgba(191,191,191,0) 100%); /* W3C */
}
.nav-progress__item.active:before {
    left: auto;
    right: 100%;
    background: linear-gradient(to left, rgba(191,191,191,1) 0%,rgba(191,191,191,0) 100%); /* W3C */
}
.active>.nav-progress__link:before,
.active>.nav-progress__link:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 100%;
    width: 0;
    height: 0;
    margin:0 -1px;
    border-style: solid;
    border-width: 54px 22px 0 0;
    border-color: #f2f2f2 transparent transparent transparent;
    z-index: 1;
}
.active>.nav-progress__link:before {
    left: auto;
    right: 100%;
    border-width: 0 0 54px 22px;
    border-color: transparent transparent #f2f2f2 transparent;
}

/*Avaliability states*/
.avaliability-state {
    display: inline-block;
    height: 16px;
    width: 16px;
    margin-right: 8px;
    vertical-align: -2px;
    border-radius: 20px;
}
.avaliability-state--success {
    background: #3bc605;
}
.avaliability-state--warning {
    background: #f5bb0b;
}
.avaliability-state--danger {
    background: #0064aa;
}

/*Cart List*/
.cart-list {
    font-size: 14px;
}
.cart-list__item {
    margin-top: 25px;
    margin-bottom: 25px;
    padding-bottom: 20px;
    border-bottom: 1px solid #e6e6e6;
}
@media screen and (min-width: 768px) {
    .cart-list__item {
        margin-top: 17px;
        margin-bottom: 17px;
        padding-bottom: 10px;
    }
}
.cart-list__title.cart-list__title {
    font-size: 18px;
    line-height: 1.1;
    margin-bottom: 6px;
}
@media screen and (max-width: 1550px) {
    .shop-welcome-text { margin-left: 28%; }
}
@media screen and (max-width: 1400px) {
    .shop-welcome-text { display: none; }
}
@media screen and (min-width: 768px) {
    .cart-list__title.cart-list__title {
        font-size: 20px;
    }
}
@media screen and (max-width: 767px) {
    .cart-list__sub-item {
        border-top: 1px solid #e6e6e6;
        padding-top: 10px;
        margin-bottom: 10px;
    }
    .cart-list__sub-item:first-child {
        border-top: none;
    }
}
.cart-list__detail {
    color: #999;
    line-height: 1.75;
    margin-top: 2px;
}
@media screen and (min-width: 768px) {
    .cart-list__detail {
        padding-left: 20px;
    }
    .cart-list__price {
        text-align: right;
    }
}


/* bugfix ticketdetail-list in ms edge*/
#ticket-detail ul li {
    list-style-image:url(#);
}

/* affiliates */
.affiliate-logo{
    margin: 20px 5px 40px 0;
}

#tickets {
    margin-bottom: 115px;
}

.js-ajaxForm{
    position: relative;
}

.busy-ind{
    text-align: center;
    position: absolute;
    top: 100px;
    left: 50%;
    margin-left: -20px;
    z-index: 100;
    height: 40px;
    width: 40px;
}

.translucent{
    opacity: 0.5;
    pointer-events: none;
}

.snapjs-right #mobile-navigation {
    display: block;
}

.password-info {
    margin-top: 2px;
    margin-left: 15px;
}

iframe[name="google_conversion_frame"] {
    height: 0;
}

.snap-drawer-right .js-mobile-nav-placeholder {
    /*padding-top: 50px;*/
}

@import "/web/static/css/style/registration-type-form.css";
