
.btn-neutral{
    overflow: hidden;
    outline: none;
    position: relative;
    z-index: 0;
    text-transform: uppercase;
    color: #000;
    font-family: RobotoCondensedRegular, sans-serif;
    font-size: 16px;
    border: 0;

    background: #f2f3f2;
    background: linear-gradient(to bottom,#fff 0,#f2f3f2 100%)!important;
    border: 1px solid #ddd;

    border-radius: 0;
    padding: 7px 21px;
    display: inline-block;
    box-shadow: 0 8px 10px rgba( 0, 0, 0, .10 );

    transition: all 0.3s;
}


.btn-main-style{
    overflow: hidden;
    outline: none;
    position: relative;
    position: relative;
    z-index: 0;
    text-align: left;
    text-transform: uppercase;
    color: #fff;
    font-family: RobotoCondensedRegular, sans-serif;
    font-size: 18px;
    background: #0064aa;
    border: 0;
    border-radius: 0;
    padding: 8px 22px;
    display: inline-block;
    box-shadow: 0 8px 10px rgba( 0, 0, 0, .10 );

    transition: all 0.3s;
}

.btn-main-style:after{
    content: '';
    width: 62px;
    height: 50px;
    position: absolute;
	-webkit-backface-visibility: hidden;
    transform: skewX(-25deg);
    top: 0px;
    bottom: -68px;
    right: -12px;
    z-index: -1;

    background: #dc0015; /* Old browsers */
    background: -moz-linear-gradient(top,  #dc0015 0%, #aa000e 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#dc0015), color-stop(100%,#aa000e)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #dc0015 0%,#aa000e 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #dc0015 0%,#aa000e 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #dc0015 0%,#aa000e 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #dc0015 0%,#aa000e 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dc0015', endColorstr='#aa000e',GradientType=0 ); /* IE6-9 */

    transition: all 0.3s;
}

.btn-main-style:hover:after {
    width: 68px;
}

.btn-main-style:hover {
    background: #e80015;
}

.btn-base-style{
    outline: none;

    min-height: 50px;

    text-align: center;
    text-transform: uppercase;
    color: #fff;
    font-family: RobotoCondensedRegular, sans-serif;
    font-size: 20px;

    background: #0064aa;
    border: 0;
    border-radius:0;
    padding: 5px 20px;
    display: inline-block;
    box-shadow: 0 8px 10px rgba( 0, 0, 0, .10 );
}

.btn-primary {
    padding: 8px 21px;
    outline: none;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: RobotoBold,sans-serif;
    font-size: 1em;
    background: #0064aa;
    border: 0;
    border-radius:0;
    box-shadow: 0 10px 10px rgba(0, 0, 0,.1);
}
.btn-primary.btn-lg {
    font-size: 20px;
    font-family: RobotoCondensedRegular, sans-serif;
    padding: 12px 18px;
}
.btn-primary--disabled,
.btn-primary--disabled:hover,
.btn-primary--disabled:active,
.btn-primary--disabled:focus,
.btn-primary[disabled] {
    background: #0064aa;
    border: none;
    opacity: .65;
    filter: alpha(opacity=65);
    box-shadow: none;
    cursor: default;
}


.btn-success, .btn-cancel, .btn-danger {
    padding: 8px 21px;
    outline: none;
    text-transform: uppercase;
    color: #FFFFFF;
    font-family: Oswald-Regular,sans-serif;
    font-size: 1em;
    background: #18a801;
    border: 0;
    border-radius:0;
    box-shadow: 0 10px 10px rgba(0, 0, 0,.1);
}

.btn-cancel {
    background: #ddd;
    color: #333;
}

.btn-danger {
    background: #d9534f;
}

.btn-success.btn-lg, .btn-cancel.btn-lg, .btn-danger.btn-lg {
    font-size: 20px;
    font-family: RobotoCondensedRegular, sans-serif;
    padding: 12px 18px;
}
.btn-success--disabled,
.btn-success--disabled:hover,
.btn-success--disabled:active,
.btn-success--disabled:focus {
    background: #18a801;
    border: none;
    opacity: .65;
    filter: alpha(opacity=65);
    box-shadow: none;
    cursor: default;
}

/*----- Button  NEWSLETTER, KONTAKT, ROUTE, SUCHE ---------*/


.btn-send-message,
.btn-show-route{
     margin-top: 28px;
     margin-left: -44px;
 }

.btn-search{
    height: 50px;
    transition: all 0.1s linear;
}

.btn-newsletter-anmelden,
.btn-show-route{
    margin-top: 28px;
    margin-left: 0;
}

.btn-show-route{
    margin-top: 0;
}


/* ------- Button UNTERKÜNFTE -------- */

.btn-show-unterkuenfte,
.btn-show-events,
.btn-white {
    border: none;
    outline: none;
    overflow: hidden;
    position: relative;
    margin-top: 15px;
    margin-bottom: 30px;
    text-transform: uppercase;
    font-family: RobotoCondensedRegular, sans-serif;

    text-align: left;
    color: #0064aa;
    font-size: 20px;
    background: #fff;
    padding: 10px 15px;
}

/*.btn-show-unterkuenfte:hover,*/
/*.btn-show-events:hover,*/
/*.btn-white:hover{*/
    /*text-shadow: 0 0 5px #ccc;*/
    /*transition: all .2s linear;*/
    /*text-decoration: none;*/
    /*color: #0064aa;*/
/*}*/


/* -------- Button ROUTENPLANER PORTAL ------- */
.btn-routenplaner{
    overflow: hidden;
    position: relative;

    height: 50px;
    text-align: left;
    text-transform: uppercase;
    color: #fff;
    font-family: RobotoCondensedRegular, sans-serif;
    font-size: 18px;
    background: #0064aa;
    border: 0;
    border-radius: 0;
    padding: 5px 15px;
    padding-right: 64px;
    display: inline-block;
    box-shadow: 0 8px 10px rgba( 0, 0, 0, .10 );
	padding-top: 13px;

	transition: all 200ms ease-in-out;
}
.btn-routenplaner:hover{
	color: #fff;
	text-decoration: none;
	/*box-shadow: 0 5px 5px rgba( 0, 0, 0, .20 );*/
}

.btn-routenplaner i {
    position: absolute;
    top: 0px;
    right: 0;
    padding-right: 16px;
    margin-left: 22px;
    z-index: 1;
}

.btn-routenplaner:after {
    content: '';
    width: 58px;
    position: absolute;
	-webkit-backface-visibility: hidden;
    transform: skewX(-25deg);
    top: 0px;
    bottom: -68px;
    right: 0px;
    background: rgb(0,100,170);
    background: linear-gradient(to bottom,  rgba(0,100,170,1) 0%,rgba(0,70,121,1) 64%,rgba(0,70,121,1) 100%);
}

.btn-routenplaner .icon-arrow:before,
.btn-portal-slider .icon-arrow:before{
    font-family: iconfont;
    padding-left: 181px;
    padding-top: 15px;
    padding-bottom: 15px;
}

/* ----- Button PORTAL HEADER SLIDER ------ */


.btn-portal-slider{
    outline: none;
    overflow: hidden;
    position: relative;

    height: 50px;
    text-align: left;
    text-transform: uppercase;
    color: #fff;
    font-family: RobotoCondensedRegular, sans-serif;
    font-size: 18px;
    background: #0064aa;
    border: 0;
    border-radius: 0;
    padding: 13px 64px 5px 15px;
    display: inline-block;
    box-shadow: 0 8px 10px rgba( 0, 0, 0, .10 );

    transition: all 90ms ease-out;
    transform: translateZ(0);
}

.btn-portal-slider i{
    position: absolute;
    top: 14px;
    right: 0;
    padding-top: 2px;
    padding-right: 16px;
    margin-left: 22px;

    z-index: 1;
}

.btn-portal-slider:after{
    content: '';
    width: 58px;
    position: absolute;
	-webkit-backface-visibility: hidden;
    transform: skewX(-25deg);
    top: 0px;
    bottom: -68px;
    right: 0px;

    transition: all 0.3s;

    background: #dc0015; /* Old browsers */
    background: -moz-linear-gradient(top,  #dc0015 0%, #aa000e 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,#dc0015), color-stop(100%,#aa000e)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  #dc0015 0%,#aa000e 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  #dc0015 0%,#aa000e 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  #dc0015 0%,#aa000e 100%); /* IE10+ */
    background: linear-gradient(to bottom,  #dc0015 0%,#aa000e 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#dc0015', endColorstr='#aa000e',GradientType=0 ); /* IE6-9 */
}
.btn-portal-slider:focus,
.btn-portal-slider:active,
.btn-portal-slider:hover {
    color: #fff;
    text-decoration: none;
    transform: scale(1.02) translateZ(0);

}

/*todo*/
.animation-shine:before,
.btn-portal-slider:before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    background: linear-gradient(to right, rgba(255,255,255,0) 0%, rgba(255,255,255,.3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
}
.animation-shine:hover:before,
.animation-shine:focus:before,
.btn-portal-slider:hover:before,
.btn-portal-slider:focus:before {
    -webkit-animation: shine .75s;
    animation: shine .75s;
}
@keyframes shine {
    100% {
        left: 125%;
    }
}

.btn-portal-slider:hover:after {
    /*width: 65px;*/
}



/* ----- Button RESTAURANTS, DARKPAGE ----- */

.btn-go-to-homepage{
    margin-top: 40px;
}

.btn-go-to-homepage .icon-arrow{
    margin-left: 35px;
    font-size: 16px;
}

.btn-filter-presse,
.btn-show-restaurants{
    float: right;
}

.btn-show-restaurants,
.btn-darkpage,
.btn-filter-presse{

    height: 50px;
    text-align: left;
    font-size: 18px;
    padding-right: 68px;
}


.btn-show-restaurants i,
.btn-darkpage i,
.btn-filter-presse i{
    position: absolute;
    top: 0px;
    right: 0;
    padding-top: 15px;
    padding-right: 20px;

    margin-left: 22px;
}

.btn-show-restaurants .icon-locator-pin:before{
    font-family: iconfont;
}


.btn-go-to-tab-content{
    position: absolute;
    bottom: 0;
    left: 50%;
    margin-left: -64px;
}


/* ----------------------------------- MEDIA QUERIES ---------------------------------*/

/* ----------------------- 0 - 768px ----------------------- */
@media (max-width: 767px) {

    .btn-send-message,
    .btn-newsletter-anmelden,
    .btn-show-route{
        font-size: 18px;
    }

    .btn-filter-presse,
    .btn-show-restaurants{
        float: left;
        margin-top: 10px;
    }

    .btn-show-restaurants{
        margin-left: 40px;
    }
}

@media screen and (max-width: 767px) {
    .btn-block-xs {
        display: block;
        width: 100%;
        white-space: normal;
    }
}
