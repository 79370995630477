
.snap-drawer-right .nav{
    background-color: #f2f2f2;
}
.snap-drawer-right li{
    border-bottom: 1px solid #d9d9d9;
}

.snap-drawer-right li > .icon{
    color: #999;
    font-size: 12px;
    position: absolute;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
    right: 0px;
    padding: 10px;
    top: 5px;
}

.snap-drawer-right li.open > .icon {
    transform: rotate(0deg);
}

.snap-drawer-right ul{
    padding: 0 15px;
}

.snap-drawer-right li a{
    font-size: 20px;
    color: #004678;
    padding: 10px 8px;
}

.snap-drawer-right #site-nav > li.active > a{
    color: #004678;
}

.snap-drawer-right .dropdown-navigation li a{
    font-size: 18px;
    color: #0064aa;
    display: block;
    padding: 0;
}

.snap-drawer-right .dropdown-navigation li .dropdown-navigation li a{
    color: #000;
}

.snap-drawer-right .dropdown-navigation ul{
    list-style: none;
    padding-left: 11px;
    padding-right: 0;
}

.snap-drawer-right .dropdown-navigation li{
    padding: 10px 0;
    position: relative;
}

.snap-drawer-right .dropdown-navigation li:first-child{
    border-top: 1px solid #d9d9d9;
}

.snap-drawer-right .dropdown-navigation li:last-child{
   border: none;
}

.mobile-nav-buttons {
    padding: 0 15px;
    margin-top: 15px;
}
.mobile-nav-buttons__btn {
    border-radius: 0;
    background: #333333;
    color: #d9d9d9;
    padding: 9px 15px;
}
.mobile-nav-buttons__btn:hover,
.mobile-nav-buttons__btn:active,
.mobile-nav-buttons__btn:focus {
    color: #d9d9d9;
}
.mobile-nav-buttons__btn .icon {
    font-size: 18px;
    vertical-align: -.2em;
    margin-right: 4px;
}