.contact-headline h2 {
    font-family: RobotoCondensedBold, sans-serif;
    font-size: 24px;
    margin-top: 0;
}

.event-arrival{
    padding-top: 10px;
}

.event-contact{
    padding-top: 20px;
    padding-bottom: 15px;
}
.date-container{
    margin-bottom: 10px;
}
.details-date,
.details-place,
.details-category,
.details-people,
.details-calendar{
    font-family: RobotoBold, sans-serif;
    position: relative;
    padding-left: 25px;
}

.details-place:before{
    padding-left: 3px;
    content: "\E00E";
}

.details-date:before{
    content: "\E006";
 }

.details-category:before,
.teaser-category:before{
    content: "\E00C";
}

.details-people:before {
    content: "\E01B";
}

.details-calendar:before {
    content: "\E003";
}

.details-category:before,
.details-date:before,
.details-place:before,
.details-people:before,
.details-calendar:before,
.teaser-category:before{
    font-family: iconfont;
    color: #999;
    position: absolute;
    left: 0;
}

.teaser-category:before{
    font-size: 14px;
    top: 1px;
}

.event-info i{
    color: #999;
}

.event-info .icon-status-closed,
.event-info .icon-status-opened{
    color: #0064aa;
    font-size: 14px;
    padding-right: 4px;
    top: 1px;
    position: relative;
}
.event-info span:first-child .icon-status-closed,
.event-info span:first-child .icon-status-opened{
    padding-right: 2px;
}

.event-info .icon-status-opened{
    color: #08aa00;
}

/* ----------------------------------------- Event Tabs ----------------------------------------- */

.event-tabs .nav-tabs > li > a,
.experiences-contact-tabs .nav-tabs > li > a{
    border-radius: 0;
    border: 1px solid #ddd;
}

.event-tabs .icon-calendar,
.event-tabs .icon-list{
    padding-right: 5px;
}

.event-tabs .icon-list{
    font-size: 13px;
}

.event-tabs .icon-calendar{
    font-size: 15px;
}

.event-tabs ul > li:first-child{
    margin-left: 17px;
}

.event-tabs ul > li,
.experiences-contact-tabs ul > li{
    color: #666;

    margin-right: 4px;

    font-family: RobotoCondensedLight, sans-serif;
    font-size: 20px;
}

.event-tabs ul > li > a,
.experiences-contact-tabs ul > li > a{
    color: #666;

    font-family: RobotoCondensedLight, sans-serif;

    font-size: 20px;

    margin-right: 0;
}

.event-tabs ul > li.active > a:hover,
.event-tabs ul > li.active > a:link,
.experiences-contact-tabs ul > li.active > a:hover,
.experiences-contact-tabs ul > li.active > a:link{
    color: #0064aa;
    background: #ffffff;
    border-bottom-color: transparent;
}

.month-box {
    position: relative;
    border-bottom: 3px solid #bfbfbf;
    font-family: RobotoLight,sans-serif;
    font-size: 16px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 15px;
    cursor: pointer;
}

.arrow_box_event_active{
    position: relative;
    border-bottom: 3px solid #0064aa;
    font-family: RobotoLight,sans-serif;
    font-size: 16px;
    padding-bottom: 3px;
    padding-left: 15px;
    padding-right: 15px;
}
.arrow_box_event_active:after{
    top: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(220, 0, 20, 0);
    border-top-color: #0064aa;
    border-width: 8px;
    margin-left: -10px;
}

.event-liste-tab{
    margin-top: 25px;
}

/* ----------------------------------------- Calendar Search Events ----------------------------------------- */

.search-events{
    margin-top: 30px;
    border-bottom: 1px solid #ddd;
    padding-bottom: 20px;
}

.txt-from,
.txt-to{
    text-transform: uppercase;
    position: absolute;
    top: 13px;
    left: 10px;
    color: #333;
    width:50px;
    text-align:right;
    font-family: RobotoCondensedLight, sans-serif;
    font-size: 16px;
}

.periode-to label,
.periode-from label{
    position: relative;
}


.periode-from i,
.periode-to i {
    color: #a3a3a3;
    position: absolute;
    right: 10px;
    top: 17px;
    font-size: 16px;
}

.periode-from .form-group,
.periode-to .form-group{
    width: 145px;
    margin-left: 60px;
}

.periode-from .form-control,
.periode-to .form-control{
    height: 50px;
    border-radius: 0;
    cursor: pointer;
    background: rgb(255,255,255);
    background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%, rgba(249,249,249,1) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(0%,rgba(255,255,255,1)), color-stop(100%,rgba(249,249,249,1)));
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%,rgba(249,249,249,1) 100%);
    background: -o-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%,rgba(249,249,249,1) 100%);
    background: -ms-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 0%,rgba(249,249,249,1) 100%);
    background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 0%,rgba(249,249,249,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f9f9f9',GradientType=1 );
}

.paging-row .text-left {
    margin-top: 20px;
}

.paging-row .pagination>.active>a,
.paging-row .pagination>.active>span,
.paging-row .pagination>.active>a:hover,
.paging-row .pagination>.active>span:hover,
.paging-row .pagination>.active>a:focus,
.paging-row .pagination>.active>span:focus {
    z-index: 2;
    color: #0064aa;
    font-weight: 700;
    cursor: default;
    background-color: transparent;
    border-color: #d9d9d9;
}

.paging-row ul > li > a{
    text-decoration: none;

    color: #999;

    font-family: RobotoLight, sans-serif;
    font-size: 16px;
}

.paging-row .pagination > li > a{
    border: none;
    padding: 0 12px;
    border-left: 1px solid #d9d9d9;
}

.paging-row .pagination > li:first-child > a{
    border-left: none;
}

.paging-row .pagination > li > a:hover,
.paging-row  .pagination > li > a:focus{
    color: #333;
    background-color: transparent;
}

.paging-row .icon-arrow{
    font-size: 14px;
}


.event-pagination-month ul > li > a{
    text-decoration: none;

    color: #333;

    font-family: RobotoLight,sans-serif;
    font-size: 16px;
}

.event-pagination-month .pagination > li > a{
    background-color: transparent;
    border: none;
    border-bottom: 2px solid #bfbfbf;
    margin-left: 20px;
    margin-right: 20px;
}

.event-pagination-month .pagination > li:first-child > a,
.event-pagination-month .pagination > li:last-child > a{
    border: none;
}


.event-pagination-month .pagination > li > a:hover,
.event-pagination-month .pagination > li > span:hover
{
    background-color: transparent;
    color: #333;
}

.event-pagination-month .pagination > li > a:focus,
.event-pagination-month .pagination > li > span:focus{
    background-color: transparent;
    border-bottom-color: #0064aa;
    color: #333;
}

.event-pagination-month .icon-arrow{
    color: #bfbfbf;
    font-size: 19px;
    position: absolute;
    top: 10px;
}

.month-slider{
    padding-left: 60px;
    padding-right: 75px;
}

.month-slider .slick-list{
    height: 34px;
    margin-left: 20px;
}

.month-slider .slick-next:before{
    content: "\E002";
    font-family: 'iconfont';
    color: #bfbfbf;
}

.month-slider .slick-prev:before{
    content: "\E002";
    font-family: 'iconfont';

    color: #bfbfbf;
}

.month-slider .slick-prev{
    transform: rotate(180deg);
    left: 17px;
}
.month-slider .slick-next{
    right: 40px;
}

.item.slick-slide.slick-active {
    width: 139px !important;
}

/* ----------------------------------------- ERLEBNISSE Content ----------------------------------------- */


.checkbox-filter-category,
.filter-news{
    margin-top: 20px;
    padding-bottom: 25px;
    padding-top: 25px;
    border-bottom: 1px solid #ddd;
    border-top: 1px solid #ddd;
}


.buy-experience{
    height: 90px;
    border: 1px solid #ddd;

    background: rgb(255,255,255);
    background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,1) 40%, rgba(242,242,242,1) 64%, rgba(242,242,242,1) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,1)), color-stop(40%,rgba(255,255,255,1)), color-stop(64%,rgba(242,242,242,1)), color-stop(100%,rgba(242,242,242,1)));
    background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 40%,rgba(242,242,242,1) 64%,rgba(242,242,242,1) 100%);
    background: -o-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 40%,rgba(242,242,242,1) 64%,rgba(242,242,242,1) 100%);
    background: -ms-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 40%,rgba(242,242,242,1) 64%,rgba(242,242,242,1) 100%);
    background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,1) 40%,rgba(242,242,242,1) 64%,rgba(242,242,242,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#f2f2f2',GradientType=1 );

}

.buy-experience{
}

.buy-experience-date {
    color: #a3a3a3;
    top: 17px;
    font-size: 16px;
}

.txt-date,
.txt-anzahl,
.txt-totalprice{
    position: absolute;
    text-transform: uppercase;
    color: #333;

    font-family: RobotoCondensedLight, sans-serif;
    font-size: 16px;
}

.txt-date{
    margin-top: 18px;
    margin-left: 14px;
}

.buy-experience-date .form-group{
    width: 150px;

    margin-left: 70px;
    margin-top: 3px;
}

.buy-experience-date i {
    color: #a3a3a3;
    position: absolute;
    margin-left: 75px;
    margin-top: -33px;
    font-size: 16px;
}

.experiences-contact-tabs{
    padding-bottom: 40px;
}

.buy-experience-anzahl .form-control{
    border-radius: 0;
    height: 50px;

    margin-top: 20px;
    margin-left: 30px;

    background: rgb(255,255,255);
    background: -moz-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 1%, rgba(250,250,250,1) 100%);
    background: -webkit-gradient(radial, center center, 0px, center center, 100%, color-stop(1%,rgba(255,255,255,1)), color-stop(100%,rgba(250,250,250,1)));
    background: -webkit-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 1%,rgba(250,250,250,1) 100%);
    background: -o-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 1%,rgba(250,250,250,1) 100%);
    background: -ms-radial-gradient(center, ellipse cover,  rgba(255,255,255,1) 1%,rgba(250,250,250,1) 100%);
    background: radial-gradient(ellipse at center,  rgba(255,255,255,1) 1%,rgba(250,250,250,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffffff', endColorstr='#fafafa',GradientType=1 );

}

.buy-experience-anzahl .form-group{
    width: 84px;

}

.txt-anzahl{
    margin-top: 32px;
    margin-left: -30px;
}

.txt-totalprice{
    margin-top: 32px;
}

.btn-buy-experience{
    height: 50px;
    margin-top: 18px;
    margin-left: -44px;

    text-align: center;

    padding: 5px 44px;
}

.contact-map{
    position: relative;
    display: inline-block;
    cursor: default;
}

.contact-map-zoom-plus,
.contact-map-zoom-minus{
    position: absolute;
    cursor: pointer;

    bottom: 25px;

    width: 55px;
    height: 55px;

    background-color: #fff;
}

.contact-map-zoom-plus{
    right: 97px;
    bottom: 25px;
}

.contact-map-zoom-plus:before{
    position: absolute;

    content: "\E028";
    font-family: 'iconfont';
    color: #999;

    font-size: 20px;
    margin-left: 16px;
    margin-top: 12px;
}

.contact-map-zoom-plus:hover,
.contact-map-zoom-minus:hover{
    text-decoration: none;
}

.contact-map-zoom-minus{
    position: absolute;
    cursor: pointer;

    width: 55px;
    height: 55px;
    right: 102px;
    bottom: 25px;

    background-color: #fff;
}

.contact-map-zoom-minus{
    right: 42px;
    border-left: 1px solid #d9d9d9;
}

.contact-map-zoom-minus:before{
    position: absolute;

    content: "\E029";
    font-family: 'iconfont';
    color: #999;

    font-size: 2px;
    margin-left: 16px;
    margin-top: 25px;
}

.shop-ticket-teaser{
    padding: 20px;
    border: 1px solid #ccc;

    background: -moz-linear-gradient(left,  rgba(255,255,255,0) 0%, rgba(255,255,255,0.5) 50%, rgba(229,229,229,1) 100%);
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,0)), color-stop(50%,rgba(255,255,255,0.5)), color-stop(100%,rgba(229,229,229,1)));
    background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 50%,rgba(229,229,229,1) 100%);
    background: -o-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 50%,rgba(229,229,229,1) 100%);
    background: -ms-linear-gradient(left,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 50%,rgba(229,229,229,1) 100%);
    background: linear-gradient(to right,  rgba(255,255,255,0) 0%,rgba(255,255,255,0.5) 50%,rgba(229,229,229,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#e5e5e5',GradientType=1 );
}

.shop-ticket-teaser .tickets-from-label, .shop-ticket-teaser .teaser-text{
    font-size: 14px;
}

.shop-ticket-teaser .btn-primary{
    padding: 10px 21px;
}

/* ----------------------------------------- NEWS Content ----------------------------------------- */


.select-category label{
    float: left;
    margin-top: 15px;
    padding-right: 10px !important;
}

.select-category select{
    width: 200px;
}

.filter-news{
    padding-bottom: 10px;
}


/* ----------------------------------------- MEDIA  QUERIES ----------------------------------------- */


/* ----------------------- 991px - 1199px ------------z.B. iPad----------- */
@media (min-width: 991px) and (max-width: 1199px) {

    /* Erlebnisse */

    .buy-experience-date{
        margin-left: -15px;
    }

    .buy-experience-anzahl{
        margin-left: -15px;
    }

    .buy-experience-price{
        margin-left: 25px;
    }

    .btn-buy-experience{
        margin-left: -10px;
        width: 150px;
        font-size: 18px;
    }

    .buy-experience-date .form-group{
       width: 100px;
    }


}

/* ----------------------- 0 - 991px ----------------------- */
@media (max-width: 991px) {

    /* Events */

    .txt-date {
        margin-top: -12px;
        margin-left: 3px;
    }

    .txt-anzahl {
        margin-top: 5px;
        margin-left: -7px;
    }

    .buy-experience-date .form-group {
        width: 100px;
        margin-left: 0;
        margin-top: 11px;
    }

    .buy-experience-anzahl .form-control {
        margin-top: 28px;
        margin-left: -7px;
    }

    .txt-totalprice {
        position: relative;
    }

    .btn-buy-experience {
        margin-top: 26px;
        margin-left: 0;
    }

    .buy-experience-btn{
        text-align: center;
    }

    .buy-experience-price{
        margin-top: 25px;
        text-align: center;
    }

    .move-for-xs{
        margin-left: 110px;
    }


    /* Erlebnisse */

    .buy-experience{
        height: 250px;
    }

}

/* ----------------------- 0 - 768px ----------------------- */

@media (max-width: 768px) {

    /* Media Queries EVENTS Beginn */

    .event-sites,
    .news-paging{
        text-align: center;
    }

    .periode-from,
    .periode-to{
        margin-left: -35px;
    }

    .search-events.events .periode-from,
    .search-events.events .periode-to{
        margin-left: -15px;
    }

    .event-pagination-month .pagination > li > a {
        margin-left: 10px;
        margin-right: 10px;
    }

    .event-pagination-month ul > li > a {
        font-size: 14px;
    }

    .event-pagination-month .icon-arrow {
        font-size: 17px;
    }

    .move-for-xs{
        margin-left: 32px;
    }

    /* News */
    .news-paging.col-xs-12{
        padding-top: 10px;
    }

}


/* ----------------------- 0 - 360px ----------------------- */

@media (max-width: 360px) {

    .event-tabs ul > li > a {
        font-size: 18px;
    }

}

/* ----------------------- 0 - 340px ----------------------- */

@media (max-width: 340px) {

    .event-tabs ul > li > a {
        font-size: 15px;
    }

    .event-tabs .icon-calendar {
        font-size: 13px;
    }
    .event-tabs .icon-list {
        font-size: 11px;
    }

    .move-for-xs{
        margin-left: 14px;
    }

}
